import { FormInstance, message } from 'antd';
import { roleService } from 'permission/service/roleService';
import { RolePermissionType } from 'permission/type/roleType';

type RoleDetailHandler = {
  handleUpdate: () => void;
};

type FormValues = {
  name: string;
  permission?: number[];
  description?: string;
};

export const getRoleEditHandlers = ({
  id,
  form,
}: {
  form: FormInstance;
  id?: string;
}): RoleDetailHandler => {
  const handleUpdate = async (): Promise<void> => {
    try {
      const values = (await form.validateFields()) as FormValues;
      const permissionIds = form.getFieldValue('permission') || [];
      const formData: RolePermissionType = {
        id: Number(id),
        name: values.name,
        description: values.description || '',
        permissions: permissionIds,
      };
      await roleService.updateRole(Number(id), formData);
      await roleService.updatePermission(Number(id), permissionIds);
      message.success('Role has been updated.');
    } catch (error) {
      message.error('An error occurred while saving.');
      console.error(error);
    }
  };

  return {
    handleUpdate,
  };
};

import { Table, Typography } from 'antd';
import React from 'react';
import { PermissionType } from '../../../type/roleType';

export const RolePermissionTable = ({
  permissions,
}: {
  permissions: PermissionType[];
}): JSX.Element => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '코드',
      dataIndex: 'code',
      key: 'code',
    },
  ];

  return (
    <div style={{ padding: '16px' }}>
      <Typography.Title level={4}>Permission List</Typography.Title>
      <Table
        columns={columns}
        dataSource={permissions}
        rowKey="id"
        bordered
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
};

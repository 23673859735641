import { MenuProps } from 'antd';
import SidebarComponent from 'components/sidebar/Sidebar';
import { METADATA_SUB_PATHS } from 'constants/index';
import {
  ADMIN_MENU_ITEMS,
  CONTENT_MENU_ITEMS,
  CONTRACT_MENU_ITEMS,
  REVENUE_MENU_ITEMS,
} from 'constants/menu';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { accountStore } from 'store/accountStore';
import { default as CustomBreadcrumb } from '../breadcrumb/Breadcrumb';
import './BasePage.css';
import PageHeader from './PageHeader';

export interface BaseProps {
  children: React.ReactNode;
}

const useMenuFiltering = (): {
  contentMenuList: MenuProps['items'];
  contractMenuList: MenuProps['items'];
  adminMenuList: MenuProps['items'];
  revenueMenuList: MenuProps['items'];
} => {
  const menus = ['account', 'logout'].concat(accountStore.getMenus());
  const contentMenuList = useMemo(
    () => CONTENT_MENU_ITEMS!.filter(item => menus.includes(item!.key as string)),
    [menus]
  );

  const contractMenuList = useMemo(
    () => CONTRACT_MENU_ITEMS!.filter(item => menus.includes(item!.key as string)),
    [menus]
  );

  const adminMenuList = useMemo(
    () => ADMIN_MENU_ITEMS!.filter(item => menus.includes(item!.key as string)),
    [menus]
  );

  const revenueMenuList = useMemo(
    () => REVENUE_MENU_ITEMS!.filter(item => menus.includes(item!.key as string)),
    [menus]
  );

  const filteredAdminMenuList =
    accountStore.getAccount()?.platform.id === 2
      ? adminMenuList.filter(item =>
          ['account', 'user', 'logout', 'company'].includes(item!.key as string)
        )
      : adminMenuList;

  return {
    contentMenuList,
    contractMenuList,
    adminMenuList: filteredAdminMenuList,
    revenueMenuList,
  };
};

const BasePage: React.FC<BaseProps> = observer(({ children }) => {
  const location = useLocation();
  const currentPath = location.pathname.split('/')[1];
  const defaultOpenKeys = METADATA_SUB_PATHS.includes(currentPath) ? ['metadata'] : [];
  const breadcrumbs = CustomBreadcrumb();
  const breadcrumbItems = breadcrumbs.map((breadcrumb, index) => ({
    title: breadcrumb.title,
    key: index,
    href: breadcrumb.url,
  }));

  const { contentMenuList, contractMenuList, adminMenuList, revenueMenuList } = useMenuFiltering();

  useEffect(() => {
    if (!accountStore.isInit()) {
      accountStore.init().then();
    }
  }, []);

  return (
    <SidebarComponent
      contentMenuList={contentMenuList || []}
      contractMenuList={contractMenuList || []}
      revenueMenuList={revenueMenuList || []}
      currentPath={currentPath}
      defaultOpenKeys={defaultOpenKeys}
    >
      <PageHeader
        userName={accountStore.getAccount()?.username || ''}
        breadcrumbItems={breadcrumbItems}
        adminMenuList={adminMenuList || []}
      />
      <div className="base-page-component">{children}</div>
    </SidebarComponent>
  );
});

export default React.memo(BasePage);

import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Upload } from 'antd';
import { THUMBNAIL_UPLOAD_STATE } from 'constants/index';
import { thumbnailHandlers } from 'content/page/content/handlers';
import { contentService } from 'content/service/contentService';
import { ThumbnailInterface } from 'content/type';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ThumbnailListComponent } from './components';
import './Thumbnail.css';

const Thumbnail: React.FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [newThumbnailList, setNewThumbnailList] = useState<ThumbnailInterface[]>([]);
  const [uploadedThumbnailList, setUploadedThumbnailList] = useState<ThumbnailInterface[]>([]);
  const [selectedThumbnail, setSelectedThumbnail] = useState<ThumbnailInterface | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {
    handleThumbnailUpload,
    handleChange,
    handleNewThumbnailRemove,
    handleUploadedThumbnailRemove,
    handleThumbnailSelect,
    handleThumbnailChange,
    handleNewThumbnailChange,
  } = thumbnailHandlers({
    form,
    contentId: Number(id),
    selectedThumbnail,
    setNewThumbnailList,
    setUploadedThumbnailList,
    setSelectedThumbnail,
  });

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const thumbnailList = await contentService.fetchThumbnailList(Number(id));
      const uploadedThumbnailList: ThumbnailInterface[] = thumbnailList.map(
        (thumbnail: ThumbnailInterface) => {
          return {
            ...thumbnail,
            state: THUMBNAIL_UPLOAD_STATE.UPLOADED,
            originFileObj: undefined,
          };
        }
      );
      const selectedThumbnail = uploadedThumbnailList.find(
        (thumbnail: ThumbnailInterface) => thumbnail.is_representative
      );
      form.setFieldValue('newThumbnailList', []);
      form.setFieldValue('uploadedThumbnailList', uploadedThumbnailList);
      form.setFieldValue('thumbnailList', uploadedThumbnailList);
      setNewThumbnailList([]);
      setUploadedThumbnailList(uploadedThumbnailList);
      setSelectedThumbnail(selectedThumbnail || null);
    };
    fetchData().then(() => {
      setIsLoading(false);
    });
  }, [form]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="thumbnail-container">
      <div style={{ display: 'flex' }}>
        <div className="thumbnail-preview-container">
          <span> Selected Thumbnail </span>
          <img
            src={selectedThumbnail ? selectedThumbnail.file : ''}
            alt=""
            className="thumbnail-preview"
          />
        </div>
        <div style={{ width: '100%' }}>
          <div className="thumbnail-uploaded-container">
            <Upload
              key={'upload-thumbnail'}
              accept="image/*,.psd"
              multiple={true}
              showUploadList={false}
              beforeUpload={() => {
                return false; // 자동 업로드 방지
              }}
              onChange={handleChange}
              listType="text"
            >
              <Button icon={<UploadOutlined />} style={{ width: '100%', marginBottom: '10px' }}>
                {`Thumbnail Upload`}
              </Button>
            </Upload>
            <ThumbnailListComponent
              thumbnails={newThumbnailList}
              selectedThumbnail={selectedThumbnail}
              onThumbnailSelect={handleThumbnailSelect}
              onThumbnailRemove={handleNewThumbnailRemove}
              onThumbnailChange={handleThumbnailChange}
              onNewThumbnailChange={handleNewThumbnailChange}
              isNew={true}
            />
          </div>
          <div className="thumbnail-uploaded-container">
            <ThumbnailListComponent
              thumbnails={uploadedThumbnailList}
              selectedThumbnail={selectedThumbnail}
              onThumbnailSelect={handleThumbnailSelect}
              onThumbnailRemove={handleUploadedThumbnailRemove}
              onThumbnailChange={handleThumbnailChange}
              onNewThumbnailChange={handleNewThumbnailChange}
              canDownload={true}
            />
          </div>
        </div>
      </div>
      <Form.Item style={{ marginTop: 16, textAlign: 'center' }}>
        <Button type="primary" onClick={() => handleThumbnailUpload()}>
          Save
        </Button>
      </Form.Item>
    </div>
  );
};

export default Thumbnail;

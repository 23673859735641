import { FormInstance } from 'antd';
import { TransferDataHandlerInterface } from 'components/transfer/Transfer';
import { permissionService } from 'permission/service/permissionService';
import { roleService } from 'permission/service/roleService';
import { PermissionType } from 'permission/type/roleType';

type RoleDetailHandler = {
  filterOption: (input: string, item: PermissionType) => boolean;
  dataHandler: TransferDataHandlerInterface;
};

export const getRolePermissionTransferHandlers = ({
  id,
  form,
  setRefreshKey,
}: {
  form: FormInstance;
  setRefreshKey: React.Dispatch<React.SetStateAction<number>>;
  id?: string;
}): RoleDetailHandler => {
  const filterOption = (input: string, item: PermissionType): boolean => item.name?.includes(input);

  const dataHandler: TransferDataHandlerInterface = {
    fetchSourceData: async (query: Record<string, string>) => {
      const permissions = await permissionService.fetch(query);
      return {
        data: permissions.results.map((permission: PermissionType) => ({
          ...permission,
          key: permission.id,
          name: permission.name,
          disabled: false,
        })),
        count: permissions.count,
      };
    },
    fetchTargetData: async (query: Record<string, string>) => {
      const targetPermissionIds = [];
      const permissionFormData = form ? form.getFieldValue('permission') : [];

      if (!permissionFormData || permissionFormData.length === 0) {
        if (id) {
          const fetchedRules = await roleService.fetchById(Number(id), query);
          const selectedPermissions = fetchedRules.permissions.map((permission: PermissionType) =>
            Number(permission.id)
          );
          targetPermissionIds.push(...selectedPermissions);
        }
      } else {
        targetPermissionIds.push(...permissionFormData);
      }
      const { results: permissions, count } = await permissionService.fetchByIds(
        targetPermissionIds,
        query
      );
      return {
        data: permissions.map((permission: PermissionType) => ({
          ...permission,
          key: permission.id,
          name: permission.name,
          disabled: false,
        })),
        count,
      };
    },
    createWithParentId: async (ids: number[]) => {
      const permissionIds = form.getFieldValue('permission');
      if (!permissionIds) {
        form.setFieldValue('permission', ids);
      } else {
        const deduplicatedIds = [...new Set([...permissionIds, ...ids])];
        form.setFieldValue('permission', deduplicatedIds);
      }
      setRefreshKey(prev => prev + 1);
    },
    deleteWithParentId: async (ids: number[]) => {
      const permissionIds = form.getFieldValue('permission');
      if (!permissionIds) {
        form.setFieldValue('permission', '');
      } else {
        const deduplicatedIds = permissionIds.filter((id: number) => !ids.includes(id));
        form.setFieldValue('permission', deduplicatedIds.join(','));
      }
      setRefreshKey(prev => prev + 1);
    },
  };

  return {
    filterOption,
    dataHandler,
  };
};

import React, { useCallback } from 'react';
import { Navigate, Outlet, Route, RouteObject, Routes } from 'react-router-dom';
import authService from 'service/authService';
import * as AccountPage from '../domain/account';
import * as ContentPage from '../domain/content';
import * as PermissionPage from '../domain/permission';
import * as RevenuePage from '../domain/revenue';
import * as UploadPage from '../domain/upload';
type PermissionRoute = RouteObject & { menuAction: string; menu: string };

const AppRoutes: React.FC = () => {
  const ProtectedRoute: React.FC = () => {
    const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | null>(null);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
      authService
        .isAuthenticated()
        .then(isAuth => {
          setIsAuthenticated(isAuth);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, []);

    if (isLoading) {
      return null; // 또는 로딩 컴포넌트
    }

    return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
  };

  const contentRoutes: PermissionRoute[] = [
    {
      path: '/',
      element: <ContentPage.ContentPage />,
      menu: 'content',
      menuAction: 'read',
    },
    {
      path: '/main',
      element: <ContentPage.ContentPage />,
      menu: 'content',
      menuAction: 'read',
    },
    {
      path: '/library',
      element: <ContentPage.ContentPage />,
      menu: 'content',
      menuAction: 'read',
    },
    {
      path: '/playlist',
      element: <ContentPage.PlaylistPage />,
      menu: 'content',
      menuAction: 'read',
    },
    {
      path: '/playlists/edit/:id',
      element: <ContentPage.PlaylistEditPage />,
      menu: 'content',
      menuAction: 'edit',
    },
    {
      path: '/playlists/add',
      element: <ContentPage.PlaylistAddPage />,
      menu: 'content',
      menuAction: 'add',
    },
    {
      path: '/video/detail/:id',
      element: <ContentPage.ContentEditPage />,
      menu: 'content',
      menuAction: 'edit',
    },
  ];
  const uploadRoutes: PermissionRoute[] = [
    {
      path: '/import-upload',
      element: <UploadPage.ImportUploadPage />,
      menu: 'import-upload',
      menuAction: 'read',
    },
    {
      path: '/upload/video',
      element: <UploadPage.UploadVideoPage />,
      menu: 'upload',
      menuAction: 'read',
    },
    {
      path: '/video-source',
      element: <UploadPage.VideoSourcePage />,
      menu: 'video-source',
      menuAction: 'read',
    },
    {
      path: '/video-source/add',
      element: <UploadPage.VideoSourceAddPage />,
      menu: 'video-source',
      menuAction: 'add',
    },
    {
      path: '/video-source/odk',
      element: <UploadPage.VideoSourceODKPage />,
      menu: 'video-source',
      menuAction: 'read',
    },
    {
      path: '/video-source/edit/:id',
      element: <UploadPage.VideoSourceEditPage />,
      menu: 'video-source',
      menuAction: 'edit',
    },
    {
      path: '/video-source/content',
      element: <UploadPage.VideoSourceContentListPage />,
      menu: 'video-source',
      menuAction: 'read',
    },
  ];
  const userRoutes: PermissionRoute[] = [
    {
      path: '/user',
      element: <AccountPage.UserPage />,
      menu: 'user',
      menuAction: 'read',
    },
    {
      path: '/user/add',
      element: <AccountPage.UserAddPage />,
      menu: 'user',
      menuAction: 'add',
    },
    {
      path: '/user/edit/:id',
      element: <AccountPage.UserEditPage />,
      menu: 'user',
      menuAction: 'edit',
    },
    {
      path: '/account',
      element: <AccountPage.AccountPage />,
      menu: 'account',
      menuAction: 'read',
    },
    {
      path: '/content-provider',
      element: <AccountPage.ContentProviderPage />,
      menu: 'content-provider',
      menuAction: 'read',
    },
    {
      path: '/content-provider/add',
      element: <AccountPage.ContentProviderAddPage />,
      menu: 'content-provider',
      menuAction: 'add',
    },
    {
      path: '/content-provider/edit/:id',
      element: <AccountPage.ContentProviderEditPage />,
      menu: 'content-provider',
      menuAction: 'edit',
    },
  ];
  const metadataStructureRoutes: PermissionRoute[] = [
    {
      path: '/series',
      element: <ContentPage.SeriesPage />,
      menu: 'series',
      menuAction: 'read',
    },
    {
      path: '/series/edit/:id',
      element: <ContentPage.SeriesEditPage />,
      menu: 'series',
      menuAction: 'edit',
    },
    {
      path: '/series/add',
      element: <ContentPage.SeriesAddPage />,
      menu: 'series',
      menuAction: 'add',
    },
    {
      path: '/season',
      element: <ContentPage.SeasonPage />,
      menu: 'season',
      menuAction: 'read',
    },
    {
      path: '/season/add',
      element: <ContentPage.SeasonAddPage />,
      menu: 'season',
      menuAction: 'add',
    },
    {
      path: '/season/edit/:id',
      element: <ContentPage.SeasonEditPage />,
      menu: 'season',
      menuAction: 'edit',
    },
    {
      path: '/people',
      element: <ContentPage.PeoplePage />,
      menu: 'people',
      menuAction: 'read',
    },
    {
      path: '/people/add',
      element: <ContentPage.PeopleAddPage />,
      menu: 'people',
      menuAction: 'add',
    },
    {
      path: '/people/edit/:id',
      element: <ContentPage.PeopleEditPage />,
      menu: 'people',
      menuAction: 'edit',
    },
    {
      path: '/category',
      element: <ContentPage.CategoryPage />,
      menu: 'category',
      menuAction: 'read',
    },
    {
      path: '/category/add',
      element: <ContentPage.CategoryAddPage />,
      menu: 'category',
      menuAction: 'add',
    },
    {
      path: '/category/edit/:id',
      element: <ContentPage.CategoryEditPage />,
      menu: 'category',
      menuAction: 'edit',
    },
    {
      path: '/contract',
      element: <ContentPage.ContractPage />,
      menu: 'contract',
      menuAction: 'read',
    },
    {
      path: '/contract/add',
      element: <ContentPage.ContractAddPage />,
      menu: 'contract',
      menuAction: 'add',
    },
    {
      path: '/contract/edit/:id',
      element: <ContentPage.ContractEditPage />,
      menu: 'contract',
      menuAction: 'edit',
    },
  ];

  const revenueRoutes: PermissionRoute[] = [
    {
      path: '/revenue-share',
      element: <RevenuePage.RevenueSharePage />,
      menu: 'revenue-share',
      menuAction: 'read',
    },
    {
      path: '/revenue-dashboard',
      element: <RevenuePage.RevenueDashboardPage />,
      menu: 'revenue-dashboard',
      menuAction: 'read',
    },
  ];

  const permissionRoutes: PermissionRoute[] = [
    {
      path: '/role',
      element: <PermissionPage.RolePage />,
      menu: 'role',
      menuAction: 'read',
    },
    {
      path: '/role/add',
      element: <PermissionPage.RoleAdd />,
      menu: 'role',
      menuAction: 'add',
    },
    {
      path: '/role/edit/:id',
      element: <PermissionPage.RoleEdit />,
      menu: 'role',
      menuAction: 'edit',
    },
  ];

  const renderRoutes = useCallback(
    (routes: RouteObject[]) =>
      routes.map((route, index) => <Route key={index} path={route.path} element={route.element} />),
    []
  );

  return (
    <Routes>
      <Route path="/login" element={<AccountPage.LoginPage />} />
      <Route element={<ProtectedRoute />}>
        {renderRoutes(contentRoutes)}
        {renderRoutes(uploadRoutes)}
        {renderRoutes(userRoutes)}
        {renderRoutes(metadataStructureRoutes)}
        {renderRoutes(revenueRoutes)}
        {renderRoutes(permissionRoutes)}
      </Route>
    </Routes>
  );
};

export default AppRoutes;

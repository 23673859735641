import { Button, Card, Form, Input, Select } from 'antd';
import { ACTION_TITLE } from 'constants/constant';
import { observer } from 'mobx-react';
import { roleService } from 'permission/service/roleService';
import { companyService } from 'project/company/service/companyService';
import { CompanyInterface } from 'project/company/type/companyType';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { accountStore } from 'store/accountStore';
import * as Component from '../../../../shared/components';
import { getUserAddHandlers } from './UserAddHandlers';

const UserAdd: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { handleOnClickAddButton } = getUserAddHandlers({ form, navigate });
  const [companyList, setCompanyList] = useState<CompanyInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [roleList, setRoleList] = useState<{ id: number; title: string }[]>([]);

  useEffect(() => {
    const fetchCompany = async (): Promise<void> => {
      const companyList = await companyService.fetchCompany();
      companyList.sort((a, b) => a.id - b.id);
      setCompanyList(companyList);
    };
    const fetchRole = async (): Promise<void> => {
      const roleList = await roleService.fetch();
      setRoleList(roleList.results.map(role => ({ id: role.id, title: role.name })));
    };
    const fetchPlatform = (): void => {
      const myAccount = accountStore.getAccount();
      if (myAccount) {
        form.setFieldsValue({
          platform: { ...myAccount.platform },
        });
      }
    };

    fetchCompany().then(() => {
      fetchPlatform();
      fetchRole();
      setIsLoading(false);
    });
  }, []);

  return (
    <Component.BasePageComponent>
      {isLoading ? (
        <Component.LoadingSpinnerComponent />
      ) : (
        <div className="flex justify-center items-center min-h-screen bg-gray-100 p-6">
          <Card className="w-full max-w-lg p-6 shadow-lg rounded-2xl bg-white">
            <h3
              className="text-center mb-4 text-xl font-semibold"
              style={{ color: '#000', marginBottom: '30px' }}
            >
              Change User Information
            </h3>
            <Form form={form} onFinish={handleOnClickAddButton} layout="vertical">
              <Form.Item name={['platform', 'title']} label="Platform" rules={[{ required: true }]}>
                <Input className="rounded-lg" readOnly />
              </Form.Item>
              <Form.Item
                name="username"
                label="ID"
                rules={[{ required: true, message: 'Please enter a username' }]}
              >
                <Input className="rounded-lg" />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: 'Please enter an email' },
                  { type: 'email', message: 'Please enter a valid email address' },
                ]}
              >
                <Input className="rounded-lg" />
              </Form.Item>

              <div className="grid grid-cols-2 gap-4">
                <Form.Item name="first_name" label="First Name">
                  <Input className="rounded-lg" />
                </Form.Item>
                <Form.Item name="last_name" label="Last Name">
                  <Input className="rounded-lg" />
                </Form.Item>
              </div>
              <Form.Item name={['company', 'id']} label="Organization">
                <Select className="rounded-lg">
                  {companyList.map(company => (
                    <Select.Option value={company.id} key={company.id}>
                      {company.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="role_ids" label="Role">
                <Select mode="multiple">
                  {roleList.map(role => (
                    <Select.Option value={role.id} key={role.id}>
                      {role.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" className="w-full rounded-lg text-lg">
                  {ACTION_TITLE.ADD}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      )}
    </Component.BasePageComponent>
  );
};

export default observer(UserAdd);

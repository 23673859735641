import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker } from 'antd';
import * as Components from 'components';
import { contentService } from 'content/service/contentService';
import { RevenueInterface } from 'content/type';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getRevenueColumns } from './RevenueColumns';

export const Revenue: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [selectStartYearMonth, setSelectStartYearMonth] = useState<Moment>();
  const [selectEndYearMonth, setSelectEndYearMonth] = useState<Moment>();
  const [onClickSearch, setOnClickSearch] = useState<boolean>(false);
  const columns = getRevenueColumns();

  const fetchRevenue = async (params: {
    page: number;
    pageSize: number;
    orderBy?: string;
    search?: string;
  }): Promise<{ data: RevenueInterface[]; count: number }> => {
    const queryParams = {
      content_id: id || '',
      page: params.page.toString(),
      page_size: params.pageSize.toString(),
      start_year_month: selectStartYearMonth ? selectStartYearMonth.format('YYYY-MM') : '',
      end_year_month: selectEndYearMonth ? selectEndYearMonth.format('YYYY-MM') : '',
      group_by: 'content',
    };
    const revenueData = await contentService.fetchRevenueShareData(queryParams);
    return { data: revenueData.results, count: revenueData.count };
  };

  useEffect(() => {
    if (onClickSearch) {
      setOnClickSearch(false);
    }
  }, [onClickSearch]);

  return (
    <>
      <div style={{ marginBottom: '1rem' }}>
        시작
        <DatePicker.MonthPicker
          style={{ marginLeft: '10px', marginRight: '10px' }}
          placeholder="Start Year/Month"
          value={selectStartYearMonth}
          onChange={date => setSelectStartYearMonth(date)}
        />
        종료
        <DatePicker.MonthPicker
          placeholder="End Year/Month"
          value={selectEndYearMonth}
          style={{ marginLeft: '10px', marginRight: '10px' }}
          onChange={date => setSelectEndYearMonth(date)}
        />
        <Button
          type="primary"
          icon={<SearchOutlined />}
          style={{
            marginLeft: '10px',
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid #d9d9d9',
          }}
          onClick={() => {
            setOnClickSearch(true);
          }}
        >
          search
        </Button>
      </div>
      <Components.TableComponent
        tableName="Revenue"
        dataFetch={fetchRevenue}
        columns={columns}
        isDisableIdColumn={true}
      />
    </>
  );
};

export default Revenue;

import { Button, DatePicker, Form, FormInstance, Input, Select, Switch } from 'antd';
import CustomInputNumber from 'components/CustomInputNumber';
import { ContractInterface } from 'contract/type';
import dayjs from 'dayjs';
import { CompanyInterface } from 'project/company/type/companyType';
import { PlatformInterface } from 'project/platform/type/platformType';
import React from 'react';
import * as Component from '../../../../../../shared/components';

const { Option } = Select;

const continents = [
  { value: 'AF', label: 'Africa' },
  { value: 'AN', label: 'Antarctica' },
  { value: 'AS', label: 'Asia' },
  { value: 'EU', label: 'Europe' },
  { value: 'NA', label: 'North America' },
  { value: 'OC', label: 'Oceania' },
  { value: 'SA', label: 'South America' },
];

const countries = [
  { value: 'US', label: 'United States' },
  { value: 'CN', label: 'Canada' },
  { value: 'KR', label: 'Korea' },
  { value: 'VN', label: 'Vietnam' },
];

const territoryOptions = [...continents, ...countries];

interface ContractDetailProps {
  form: FormInstance;
  companyList: CompanyInterface[];
  platformList: PlatformInterface[];
  contract?: ContractInterface;
  actionTitle?: string;
  handleOnClickButtonClick?: () => void;
  readonly?: boolean;
}

const contentTypeOptions = [
  'DRAMA',
  'MOVIE',
  'VARIETY',
  'DOCUMENTARY',
  'NEWS',
  'GENERAL_ENTERTAINMENT',
  'ANIMATION',
  'MUSIC',
];
const paymentTypeOptions = ['MG_RS', 'RS_ONLY', 'FLAT'];
const contractTypeOptions = ['OUTPUT_DEAL', 'VOLUME_DEAL', 'SINGLE_TITLE_CONTRACT', 'ANNUAL_DEAL'];
const taxTypeOptions = ['NET', 'GROSS'];
const serviceTypeOptions = ['SERVICE', 'FILE_TRANSFER', 'SIGN', 'SERVICE_EXPIRED'];
const platformsOptions = ['ODK', 'ODC', 'ODV', 'ODL', 'AMASIAN', 'ALL'];
const languageOptions = ['EN', 'KO', 'VI', 'LATI', 'CN'];

const ContractDetail: React.FC<ContractDetailProps> = ({
  form,
  contract,
  actionTitle,
  companyList,
  handleOnClickButtonClick,
  readonly = false,
}) => {
  return (
    <div>
      <div className="contract-details-page">
        {!readonly && <h1>Contract: {contract?.title}</h1>}
        <Form form={form} layout="vertical" className="contract-details-form">
          <Form.Item name="contract_no" label="Contract No" initialValue={contract?.contract_no}>
            <Input disabled={readonly} />
          </Form.Item>

          <Form.Item
            name="reference_contract_no"
            label="Reference Contract No"
            initialValue={contract?.reference_contract_no}
          >
            <Input disabled={readonly} />
          </Form.Item>

          <Form.Item name="title" label="Title" initialValue={contract?.title}>
            <Input disabled={readonly} />
          </Form.Item>

          <Form.Item name="title_en" label="Title (EN)" initialValue={contract?.title_en}>
            <Input disabled={readonly} />
          </Form.Item>

          <Form.Item
            name="licensor"
            label="Content Provider"
            initialValue={contract?.licensor}
            rules={[{ required: true, message: 'Please select a licensor' }]}
          >
            <Select disabled={readonly}>
              {companyList.map(company => (
                <Option value={company.id} key={company.id}>
                  {company.title}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="content_scopes"
            label="Content Scopes"
            initialValue={contract?.content_scopes}
            tooltip="Enter content titles and press Enter"
          >
            <Select
              disabled={readonly}
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Enter content titles"
              open={false}
              tokenSeparators={[',']}
            />
          </Form.Item>

          <Form.Item name="content_type" label="Content Type" initialValue={contract?.content_type}>
            <Select disabled={readonly}>
              {contentTypeOptions.map(option => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="payment_type" label="Payment Type" initialValue={contract?.payment_type}>
            <Select disabled={readonly}>
              {paymentTypeOptions.map(option => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="contract_type"
            label="Contract Type"
            initialValue={contract?.contract_type}
          >
            <Select disabled={readonly}>
              {contractTypeOptions.map(option => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="country" label="Country" initialValue={contract?.country}>
            <Input disabled={readonly} />
          </Form.Item>

          <Form.Item name="rs_rate" label="RS Rate" initialValue={contract?.rs_rate}>
            <CustomInputNumber
              readonly={readonly}
              min={0}
              max={100}
              precision={2}
              addonAfter="%"
              style={{ width: '200px' }}
            />
          </Form.Item>

          <Form.Item
            name="ops_cost_rate"
            label="Ops Cost Rate"
            initialValue={contract?.ops_cost_rate}
          >
            <CustomInputNumber
              readonly={readonly}
              min={0}
              max={100}
              precision={2}
              addonAfter="%"
              style={{ width: '200px' }}
            />
          </Form.Item>

          <Form.Item
            name="allowed_number_of_episodes"
            label="Allowed Number of Episodes"
            initialValue={contract?.allowed_number_of_episodes}
          >
            <CustomInputNumber readonly={readonly} style={{ width: '200px' }} />
          </Form.Item>

          <Form.Item
            name="price_per_episode"
            label="Price Per Episode"
            initialValue={contract?.price_per_episode}
          >
            <CustomInputNumber
              readonly={readonly}
              min={0}
              precision={2}
              addonAfter="$"
              style={{ width: '200px' }}
            />
          </Form.Item>

          <Form.Item name="total_cost" label="Total Cost" initialValue={contract?.total_cost}>
            <CustomInputNumber
              readonly={readonly}
              min={0}
              precision={2}
              addonAfter="$"
              style={{ width: '200px' }}
            />
          </Form.Item>

          <Form.Item name="flat_cost" label="Flat Cost" initialValue={contract?.flat_cost}>
            <CustomInputNumber
              readonly={readonly}
              min={0}
              precision={2}
              addonAfter="$"
              style={{ width: '200px' }}
            />
          </Form.Item>

          <Form.Item name="dubbing_cost" label="Dubbing Cost" initialValue={contract?.dubbing_cost}>
            <CustomInputNumber
              readonly={readonly}
              min={0}
              precision={2}
              addonAfter="$"
              style={{ width: '200px' }}
            />
          </Form.Item>

          <Form.Item
            name="subtitle_cost"
            label="Subtitle Cost"
            initialValue={contract?.subtitle_cost}
          >
            <CustomInputNumber
              readonly={readonly}
              min={0}
              precision={2}
              addonAfter="$"
              style={{ width: '200px' }}
            />
          </Form.Item>

          <Form.Item
            name="license_term"
            label="License Term (Months)"
            initialValue={contract?.license_term}
          >
            <CustomInputNumber readonly={readonly} min={0} />
          </Form.Item>

          <Form.Item name="service_type" label="Service Type" initialValue={contract?.service_type}>
            <Select disabled={readonly}>
              {serviceTypeOptions.map(option => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="signed_date"
            label="Signed Date"
            initialValue={contract?.signed_date || null}
            getValueFromEvent={(_date, dateString) => dateString || null}
            getValueProps={value => ({
              value: value ? dayjs(value, 'YYYY-MM-DD') : null,
            })}
          >
            <DatePicker disabled={readonly} format="YYYY-MM-DD" />
          </Form.Item>

          <Form.Item
            name="service_start_date"
            label="Service Start Date"
            initialValue={contract?.service_start_date || null}
            getValueFromEvent={(_date, dateString) => dateString || null}
            getValueProps={value => ({
              value: value ? dayjs(value, 'YYYY-MM-DD') : null,
            })}
          >
            <DatePicker disabled={readonly} format="YYYY-MM-DD" />
          </Form.Item>

          <Form.Item
            name="service_expire_date"
            label="Service Expire Date"
            initialValue={contract?.service_expire_date || null}
            getValueFromEvent={(_date, dateString) => dateString || null}
            getValueProps={value => ({
              value: value ? dayjs(value, 'YYYY-MM-DD') : null,
            })}
          >
            <DatePicker disabled={readonly} format="YYYY-MM-DD" />
          </Form.Item>

          <Form.Item
            name="automatic_renewal"
            label="Automatic Renewal"
            valuePropName="checked"
            initialValue={contract?.automatic_renewal ?? false}
          >
            <Switch disabled={readonly} />
          </Form.Item>

          <Form.Item
            name="sub_licensing"
            label="Sub-Licensing"
            valuePropName="checked"
            initialValue={contract?.sub_licensing ?? false}
          >
            <Switch disabled={readonly} />
          </Form.Item>

          <Form.Item
            name="exclusiveness"
            label="Exclusiveness"
            valuePropName="checked"
            initialValue={contract?.exclusiveness ?? false}
          >
            <Switch disabled={readonly} />
          </Form.Item>

          <Form.Item
            name="youtube_whitelist"
            label="YouTube Whitelist"
            valuePropName="checked"
            initialValue={contract?.youtube_whitelist ?? false}
          >
            <Switch disabled={readonly} />
          </Form.Item>

          <Form.Item
            name="full_episode_youtube_facebook"
            label="Full Episode on YouTube/Facebook"
            valuePropName="checked"
            initialValue={contract?.full_episode_youtube_facebook ?? false}
          >
            <Switch disabled={readonly} />
          </Form.Item>

          <Form.Item
            name="clip_rights_sns_youtube"
            label="Clip Rights on SNS/YouTube"
            valuePropName="checked"
            initialValue={contract?.clip_rights_sns_youtube ?? false}
          >
            <Switch disabled={readonly} />
          </Form.Item>

          <Form.Item
            name="fast_channel_availability"
            label="Fast Channel Availability"
            valuePropName="checked"
            initialValue={contract?.fast_channel_availability ?? false}
          >
            <Switch disabled={readonly} />
          </Form.Item>

          <Form.Item name="tax_type" label="Tax Type" initialValue={contract?.tax_type}>
            <Select disabled={readonly}>
              {taxTypeOptions.map(option => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="platforms" label="Platforms" initialValue={contract?.platforms}>
            <Select mode="multiple" disabled={readonly}>
              {platformsOptions.map(option => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="subtitle_languages"
            label="Subtitle Languages"
            initialValue={contract?.subtitle_languages}
          >
            <Select mode="multiple" disabled={readonly}>
              {languageOptions.map(option => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="dubbing_languages"
            label="Dubbing Languages"
            initialValue={contract?.dubbing_languages}
          >
            <Select mode="multiple" disabled={readonly}>
              {languageOptions.map(option => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="payment_schedule"
            label="Payment Schedule"
            initialValue={contract?.payment_schedule}
          >
            <Input.TextArea disabled={readonly} />
          </Form.Item>

          <Form.Item name="rs_follow_up" label="RS Follow-Up" initialValue={contract?.rs_follow_up}>
            <Input.TextArea disabled={readonly} />
          </Form.Item>

          <Form.Item
            name="contract_file_link"
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>Contract File Link</span>
                {contract?.contract_file_link && (
                  <Button
                    type="link"
                    size="small"
                    onClick={() => window.open(contract.contract_file_link, '_blank')}
                  >
                    Link
                  </Button>
                )}
              </div>
            }
            initialValue={contract?.contract_file_link}
          >
            <Input disabled={readonly} />
          </Form.Item>

          <Form.Item name="note" label="Note" initialValue={contract?.note}>
            <Input.TextArea disabled={readonly} />
          </Form.Item>

          <Form.Item
            name="service_whitelist"
            label="Service Whitelist"
            initialValue={contract?.service_whitelist}
          >
            <Select mode="multiple" disabled={readonly}>
              {territoryOptions.map(option => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="service_blacklist"
            label="Service Blacklist"
            initialValue={contract?.service_blacklist}
          >
            <Select mode="multiple" disabled={readonly}>
              {territoryOptions.map(option => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="sns_whitelist"
            label="SNS Whitelist"
            initialValue={contract?.sns_whitelist}
          >
            <Select mode="multiple" disabled={readonly}>
              {territoryOptions.map(option => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="sns_blacklist"
            label="SNS Blacklist"
            initialValue={contract?.sns_blacklist}
          >
            <Select mode="multiple" disabled={readonly}>
              {territoryOptions.map(option => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </div>
      {!readonly && (
        <div className="people-detail-update-button">
          <Component.ColorButtonComponent
            text={actionTitle || 'Update'}
            onClick={handleOnClickButtonClick}
          />
        </div>
      )}
    </div>
  );
};

export default ContractDetail;

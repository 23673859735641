import * as Component from 'components';
import { observer } from 'mobx-react';
import { companyService } from 'project/company/service/companyService';
import { CompanyInterface } from 'project/company/type/companyType';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getContentProviderColumns } from './ContentProviderColumns';
import { getContentProviderTableHandlers } from './ContentProviderHandlers';

const ContentProviderPage: React.FC = () => {
  const navigate = useNavigate();
  const { onClickEditButton, onClickDeleteButton, handleAddContentProviderClick } =
    getContentProviderTableHandlers({
      navigate,
    });

  const columns = getContentProviderColumns({ onClickEditButton, onClickDeleteButton });

  const fetchContentProvider = async (params: {
    page: number;
    pageSize: number;
    orderByField?: string;
    order?: string;
    search?: string;
  }): Promise<{ data: CompanyInterface[]; count: number }> => {
    const contentProviderList = await companyService.fetch({
      page: params.page.toString(),
      page_size: params.pageSize.toString(),
      order_by: params.orderByField || 'id',
      order: params.order || 'asc',
      search: params.search || '',
    });
    return {
      data: contentProviderList.results,
      count: contentProviderList.count,
    };
  };

  return (
    <Component.BasePageComponent>
      <Component.TableComponent<CompanyInterface>
        tableName="Content Provider"
        columns={columns}
        dataFetch={fetchContentProvider}
        handleAddClick={handleAddContentProviderClick}
      />
    </Component.BasePageComponent>
  );
};

export default observer(ContentProviderPage);

import {
  DeleteOutlined,
  DownloadOutlined,
  PlayCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Button, Modal, Space } from 'antd';
import { PreviewComponent } from 'content/page/content/components';
import { ContentInterface } from 'content/type';
import moment from 'moment';
import React from 'react';
import { TableColumnInterface } from 'src/shared/components/table/Table';
import { accountStore } from 'store/accountStore';

interface TableColumnProps {
  isModalVisible: boolean;
  selectedRecord: ContentInterface | null;
  handleOnClickTitle: (id: number) => void;
  handleOnClickSetting: (id: number) => void;
  handleCancelModal: () => void;
  handleShowModal: (record: ContentInterface) => void;
  handleOnDownload: (record: ContentInterface) => void;
  onClickDeleteButton: (id: number) => void;
}

export const getTableColumn = ({
  isModalVisible,
  selectedRecord,
  handleOnDownload,
  handleOnClickTitle,
  handleOnClickSetting,
  handleCancelModal,
  handleShowModal,
  onClickDeleteButton,
}: TableColumnProps): TableColumnInterface<ContentInterface>[] => {
  const user = accountStore.getAccount();
  const isPlatformOne = user?.platform.id === 1;

  const columns: TableColumnInterface<ContentInterface>[] = [
    {
      title: 'THUMBNAIL',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      width: '5%',
      render: (_text: string, record: ContentInterface) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            style={{ marginLeft: '10px', width: '100%', height: 'auto' }}
            src={record.thumbnail?.file ?? ''}
            alt=""
          />
        </div>
      ),
    },
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      width: '40%',
      render: (text: string, record: ContentInterface) => (
        <div className="content-title" onClick={() => handleOnClickTitle(record.id || 0)}>
          <div
            className="title-content"
            style={{
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {text}
          </div>
        </div>
      ),
    },
    {
      title: 'MEDIAHUB HOSTED',
      dataIndex: 'contents_file',
      key: 'contents_file',
      render: (_text: string, record: ContentInterface) =>
        record.contents_file?.file ? 'MEDIAHUB HOSTED' : '',
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'SERVICE',
      dataIndex: 'odk_meta',
      key: 'odk_meta',
      render: (_text: string, record: ContentInterface) => record.odk_meta?.service_name || '',
    },
    {
      title: 'IMPORTED (UTC)',
      dataIndex: 'created',
      key: 'created',
      render: (text: string, _: ContentInterface) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'ACTIONS',
      key: 'actions',
      dataIndex: 'actions',
      render: (_text: string, record: ContentInterface) => (
        <Space>
          <Button
            icon={<PlayCircleOutlined />}
            className="action-btn play-btn"
            onClick={() => handleShowModal(record)}
          />
          <Button
            icon={<DownloadOutlined />}
            onClick={() => handleOnDownload(record)}
            className="action-btn download-btn"
          />
          <Button
            icon={<SettingOutlined />}
            onClick={() => handleOnClickSetting(record.id || 1)}
            className="action-btn setting-btn"
          />
          <Button
            icon={<DeleteOutlined />}
            className="action-btn delete-btn"
            onClick={() => onClickDeleteButton(record.id || 1)}
          />
          <Modal title="Preview" open={isModalVisible} onCancel={handleCancelModal} footer={null}>
            {selectedRecord && (
              <PreviewComponent
                videoUrl={
                  selectedRecord.contents_file.file ||
                  selectedRecord.content_stream_manifests[0]?.stream_url ||
                  ''
                }
              />
            )}
          </Modal>
        </Space>
      ),
    },
  ];

  if (isPlatformOne) {
    columns.splice(6, 0, {
      title: 'DISTRIBUTIONS',
      dataIndex: 'distributions',
      key: 'distributions',
      render: (_text: string, record: ContentInterface) => (
        <span>{record.distributions?.map(d => d.content_consumer_name).join(', ')}</span>
      ),
    });
  }

  return columns;
};

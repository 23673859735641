import { Button, Form, Input } from 'antd';
import * as Components from 'components';
import { LoadingSpinnerComponent } from 'components';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { accountStore } from 'store/accountStore';
import { AccountInterface } from 'type/accountType';
import accountHandlers from './AccountDetailHandlers';

const AccountPage: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(true);
  const [account, setAccount] = useState<AccountInterface | null>(null);
  const navigate = useNavigate();
  const { handleUpdate } = accountHandlers({ form, navigate });

  useEffect(() => {
    const fetchAccount = async (): Promise<void> => {
      await accountStore.init();
      const myAccount = accountStore.getAccount();
      if (myAccount) {
        setAccount(myAccount);
        form.setFieldsValue(myAccount);
      }
    };
    fetchAccount().then(() => {
      setLoading(false);
    });
  }, [form]);

  return (
    <Components.BasePageComponent>
      {loading ? (
        <LoadingSpinnerComponent />
      ) : (
        <Form
          form={form}
          name="account"
          autoComplete="off"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '30%',
            margin: 'auto',
          }}
        >
          <Form.Item label="Platform" name={['platform', 'title']} key="platform">
            <Input readOnly />
          </Form.Item>
          <Form.Item
            label="Company"
            name={['company', 'id']}
            key="company"
            initialValue={account?.company?.title || account?.platform.title}
          >
            <Input readOnly />
          </Form.Item>
          <Form.Item label="id" name="username" key="username">
            <Input readOnly />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            key="email"
            rules={[{ required: true, message: 'Please enter your email' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="First Name"
            name="first_name"
            key="first_name"
            rules={[{ required: true, message: 'Please enter your name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="last_name"
            key="last_name"
            rules={[{ required: true, message: 'Please enter your last name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Old Password"
            name="old_password"
            key="old_password"
            rules={[{ required: false, message: 'Please enter your old password' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="new_password"
            key="new_password"
            rules={[{ required: false, message: 'Please enter your new password' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" loading={loading} onClick={handleUpdate}>
              Save
            </Button>
          </Form.Item>
        </Form>
      )}
    </Components.BasePageComponent>
  );
};

export default observer(AccountPage);

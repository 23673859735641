import { FormInstance, message } from 'antd';
import { companyService } from 'project/company/service/companyService';
import { NavigateFunction } from 'react-router-dom';
import { accountStore } from 'store/accountStore';

interface ContentProviderEditHandlersHook {
  form: FormInstance;
  navigate: NavigateFunction;
  id: string;
}

interface ContentProviderEditHandlersReturn {
  handleOnClickEditButton: () => Promise<void>;
}

export const getContentProviderEditHandlers = ({
  form,
  navigate,
  id,
}: ContentProviderEditHandlersHook): ContentProviderEditHandlersReturn => {
  const handleOnClickEditButton = async (): Promise<void> => {
    const loadingMessage = message.loading('Updating...', 0);
    try {
      const values = await form.validateFields();
      const user = accountStore.getAccount();
      await companyService.update(Number(id), {
        ...values,
        title_en: values.title_en || undefined,
        slug: values.slug || undefined,
        country: values.country || undefined,
        platform_id: user?.platform.id || 1,
      });
      loadingMessage();
      navigate('/content-provider');
    } catch (error) {
      loadingMessage();
      message.error('Failed to update : ' + error);
    }
  };

  return {
    handleOnClickEditButton,
  };
};

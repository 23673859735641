import { Layout, Menu } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { accountStore } from 'store/accountStore';
import { AccountInterface } from 'type/accountType';
import './Sidebar.css';

export interface SidebarProps {
  contentMenuList: ItemType[];
  contractMenuList: ItemType[];
  revenueMenuList: ItemType[];
  currentPath: string;
  defaultOpenKeys: string[];
  children: React.ReactNode;
}

const { Content, Footer, Sider } = Layout;

const Sidebar: React.FC<SidebarProps> = observer(
  ({
    children,
    contentMenuList,
    contractMenuList,
    revenueMenuList,
    currentPath,
    defaultOpenKeys,
  }) => {
    const navigate = useNavigate();
    const [imageUrl, setImageUrl] = useState<string>('');
    const [user, setUser] = useState<AccountInterface | null>(null);
    useEffect(() => {
      const user = accountStore.getAccount();
      if (user) {
        setImageUrl(user.platform.logo);
        setUser(user);
      }
    }, []);

    return (
      <Layout className="main-layout" hasSider>
        <Sider className="sider" width={200}>
          <button
            className="logo-button"
            onClick={() => navigate('/')}
            style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
          >
            <img src={imageUrl} className="logo" alt="main_logo" />
          </button>
          <div className="menu-header"></div>
          <div className="content-menu-header">
            <div>Content</div>
          </div>
          <Menu
            className="menu"
            theme="dark"
            mode="inline"
            items={contentMenuList}
            selectedKeys={[currentPath]}
            defaultOpenKeys={defaultOpenKeys}
          />
          {user?.platform.id === 1 && (
            <>
              {contractMenuList.length > 0 && (
                <div>
                  <div className="contract-menu-header">
                    <div>Contract</div>
                  </div>
                  <Menu
                    className="menu"
                    theme="dark"
                    mode="inline"
                    items={contractMenuList}
                    selectedKeys={[currentPath]}
                    defaultOpenKeys={defaultOpenKeys}
                  />
                </div>
              )}
              <div className="revenue-menu-header">
                <div>Revenue</div>
              </div>
              <Menu
                className="menu"
                theme="dark"
                mode="inline"
                items={revenueMenuList}
                selectedKeys={[currentPath]}
                defaultOpenKeys={defaultOpenKeys}
              />
            </>
          )}
        </Sider>
        <Layout className="layout-content">
          <Content className="content">{children}</Content>
          <Footer className="footer">ODKMedia, Inc</Footer>
        </Layout>
      </Layout>
    );
  }
);

export default React.memo(Sidebar);

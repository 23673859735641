import { FormInstance, message } from 'antd';
import { NavigateFunction } from 'react-router-dom';
import { contractService } from '../../../services/contractService';

interface DetailHandlersProps {
  id: string;
  form: FormInstance;
  navigate: NavigateFunction;
}

interface DetailHandlers {
  handleOnClickAddButtonClick: () => Promise<void>;
  handleOnClickEditButtonClick: () => Promise<void>;
  handleOnClickDeleteButtonClick: (id: number) => Promise<void>;
}

const detailHandlers = ({ id, form, navigate }: DetailHandlersProps): DetailHandlers => {
  const handleOnClickAddButtonClick = async (): Promise<void> => {
    const loadingMessage = message.loading('Creating...', 0);
    try {
      const contract = form.getFieldsValue();
      // licensor를 제외한 모든 필드에서 null/undefined 값 제거
      Object.keys(contract).forEach(key => {
        if (key !== 'licensor' && (contract[key] === null || contract[key] === undefined)) {
          delete contract[key];
        }
      });
      await contractService.create(contract);
      loadingMessage();
      navigate('/contract');
    } catch (error) {
      loadingMessage();
      message.error('Failed to create : ' + error);
    }
  };

  const handleOnClickEditButtonClick = async (): Promise<void> => {
    const loadingMessage = message.loading('Updating...', 0);
    try {
      const contract = form.getFieldsValue();
      Object.keys(contract).forEach(key => {
        if (key !== 'licensor' && (contract[key] === null || contract[key] === undefined)) {
          delete contract[key];
        }
      });
      await contractService.update(Number(id), contract);
      loadingMessage();
      navigate('/contract');
    } catch (error) {
      loadingMessage();
      message.error('Failed to update : ' + error);
    }
  };

  const handleOnClickDeleteButtonClick = async (): Promise<void> => {
    message.info('You do not have permission');
    return;
  };

  return {
    handleOnClickAddButtonClick,
    handleOnClickEditButtonClick,
    handleOnClickDeleteButtonClick,
  };
};

export default detailHandlers;

import { Button, Form, Input, Typography } from 'antd';
import * as Component from 'components';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRoleAddHandlers } from './RoleAddHandler';
import { RolePermissionTransfer } from './components/RolePermissionTransfer';
export default function RoleAdd(): React.JSX.Element {
  const navigate = useNavigate();
  const [, setRefreshKey] = useState(0);
  const [form] = Form.useForm();
  const { handleSave } = getRoleAddHandlers({
    form,
  });

  return (
    <Component.BasePageComponent>
      <Form form={form}>
        <Typography.Title level={4} style={{ marginTop: '16px', marginBottom: '20px' }}>
          Role Information
        </Typography.Title>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input name' }]}
        >
          <Input style={{ width: '15%' }} />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input style={{ width: '15%' }} />
        </Form.Item>

        <Typography.Title level={4} style={{ marginTop: '16px', marginBottom: '20px' }}>
          Permission Management
        </Typography.Title>
        <RolePermissionTransfer form={form} setRefreshKey={setRefreshKey} />
        <Form.Item style={{ marginTop: 16, textAlign: 'center' }}>
          <Button
            type="primary"
            onClick={(): void => {
              handleSave();
              navigate('/role/');
            }}
          >
            save
          </Button>
        </Form.Item>
      </Form>
    </Component.BasePageComponent>
  );
}

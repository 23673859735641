import { Button, Form, Input, Typography } from 'antd';
import * as Component from 'components';
import { roleService } from 'permission/service/roleService';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getRoleEditHandlers } from './RoleEditHandler';
import { RolePermissionTransfer } from './components/RolePermissionTransfer';

export default function RoleEdit(): React.JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [, setRefreshKey] = useState(0);
  const [form] = Form.useForm();
  const { handleUpdate } = getRoleEditHandlers({
    id: id,
    form,
  });

  const fetchRoleDetail = async (): Promise<void> => {
    const role = await roleService.fetchById(Number(id));
    form.setFieldsValue(role);
  };

  useEffect(() => {
    if (id) {
      fetchRoleDetail();
    }
  }, [id]);
  return (
    <Component.BasePageComponent>
      <Form form={form}>
        <Typography.Title level={4} style={{ marginTop: '16px', marginBottom: '20px' }}>
          Role Information
        </Typography.Title>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input name' }]}
        >
          <Input style={{ width: '15%' }} />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input style={{ width: '15%' }} />
        </Form.Item>

        <Typography.Title level={4} style={{ marginTop: '16px', marginBottom: '20px' }}>
          Permission Management
        </Typography.Title>
        <RolePermissionTransfer id={id} form={form} setRefreshKey={setRefreshKey} />
        <Form.Item style={{ marginTop: 16, textAlign: 'center' }}>
          <Button
            type="primary"
            onClick={(): void => {
              handleUpdate();
              navigate('/role/');
            }}
          >
            save
          </Button>
        </Form.Item>
      </Form>
    </Component.BasePageComponent>
  );
}

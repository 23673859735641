import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Table } from 'antd';
import { subtitleHandlers } from 'content/page/content/handlers';
import { contentService } from 'content/service/contentService';
import { SubtitleInterface } from 'content/type';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSubtitleColumns } from './SubtitleColumns';

export const Subtitle: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [dataSource, setDataSource] = useState<SubtitleInterface[]>([]);
  const [form] = Form.useForm();
  const {
    handleLanguageChange,
    handleFileChange,
    handleAddSubtitle,
    handleUpdateSubtitle,
    handleSubtitleDownload,
  } = subtitleHandlers({
    form,
    contentId: Number(id),
    setDataSource,
  });

  const columns = getSubtitleColumns({
    handleLanguageChange,
    handleFileChange,
    handleSubtitleDownload,
  });

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const subtitleList = await contentService.fetchSubtitleList(Number(id));
      setDataSource(subtitleList);
    };
    fetchData();
  }, [id]);

  return (
    <>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowKey={record => record.id.toString()}
      />
      <Button
        type="dashed"
        icon={<PlusOutlined />}
        style={{ width: '100%', marginTop: '10px' }}
        onClick={() => handleAddSubtitle(dataSource.length + 1)}
      >
        New Subtitle
      </Button>
      <Form.Item style={{ marginTop: 16, textAlign: 'center' }}>
        <Button type="primary" onClick={() => handleUpdateSubtitle()}>
          Save
        </Button>
      </Form.Item>
    </>
  );
};

export default Subtitle;

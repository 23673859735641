import { makeAutoObservable } from 'mobx';
import { AccountInterface } from 'type/accountType';
import { accountService } from '../service/accountService';

class AccountStore {
  private account: AccountInterface | null = null;
  private projectId: number | null = null;
  private role: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  public reset = (): void => {
    this.account = null;
    this.projectId = null;
    this.role = null;
  };

  public async init(): Promise<void> {
    const account = await accountService.fetch();
    this.account = account;
    const { project_id: projectId, role } = await accountService.getUserProjectRoles(
      Number(this.account?.id)
    );
    this.projectId = projectId;
    this.role = role;
  }

  public async update(account: AccountInterface): Promise<void> {
    await accountService.update(Number(this.account?.id), account);
  }

  public async updatePassword(oldPassword: string, newPassword: string): Promise<void> {
    await accountService.updatePassword(oldPassword, newPassword);
  }

  public setAccount = (account: AccountInterface): void => {
    this.account = account;
  };

  public setProject = (projectId: number): void => {
    if (this.projectId) {
      this.projectId = projectId;
    } else {
      throw new Error('Account not found');
    }
  };

  public getAccount = (): AccountInterface | null => {
    return this.account;
  };

  public getProject = (): number | null => {
    return this.projectId;
  };

  public getRole = (): string | null => {
    return this.role;
  };

  public isStaff = (): boolean => {
    return this.account?.is_staff ?? false;
  };

  public isInit = (): boolean => {
    return this.account !== null;
  };

  public getMenus = (): string[] => {
    const permissions = Array.from(this.account?.permissions ?? []);
    const permissionList = permissions.map(permission => permission.split(':')[0]);
    return permissionList;
  };

  public getPermissions = (): string[] => {
    return this.account?.permissions ?? [];
  };

  public hasPermission = (menu: string, action: string): boolean => {
    const permissions = Array.from(this.account?.permissions ?? []);
    const permission = `${menu}:${action}`;
    return permissions.includes(permission);
  };
}

export const accountStore = new AccountStore();

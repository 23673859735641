import { FormInstance, message } from 'antd';
import { RcFile } from 'antd/es/upload';
import { contentService } from 'content/service';
import { SubtitleInterface } from 'content/type';
import React from 'react';

interface SubtitleHandlersProps {
  contentId: number;
  form: FormInstance;
  setDataSource: React.Dispatch<React.SetStateAction<SubtitleInterface[]>>;
}

interface SubtitleHandlers {
  handleLanguageChange: (value: string, record: { id: string }) => void;
  handleFileChange: (info: RcFile, record: { id: string }) => void;
  handleActiveChange: (value: boolean, record: { id: string }) => void;
  handleAddSubtitle: (id: number) => void;
  handleUpdateSubtitle: () => void;
  handleSubtitleDownload: (subtitle: SubtitleInterface) => void;
}

const subtitleHandlers = ({
  form,
  contentId,
  setDataSource,
}: SubtitleHandlersProps): SubtitleHandlers => {
  const handleLanguageChange = (value: string, record: { id: string }): void => {
    setDataSource((prev: SubtitleInterface[]) => {
      const newDataSource = prev.map(subtitle =>
        subtitle.id === Number(record.id) ? { ...subtitle, lang: value } : subtitle
      );
      form.setFieldValue('subtitleList', newDataSource);
      return newDataSource;
    });
  };

  const handleFileChange = (info: RcFile, record: { id: string }): void => {
    if (info) {
      setDataSource((prev: SubtitleInterface[]) => {
        const newDataSource = prev.map(subtitle =>
          subtitle.id === Number(record.id) ? { ...subtitle, file: info } : subtitle
        );

        form.setFieldValue('subtitleList', newDataSource);
        return newDataSource;
      });
    }
  };

  const handleActiveChange = (value: boolean, record: { id: string }): void => {
    setDataSource((prev: SubtitleInterface[]) => {
      const newDataSource = prev.map(subtitle =>
        subtitle.id === Number(record.id) ? { ...subtitle, active: value } : subtitle
      );
      form.setFieldValue('subtitleList', newDataSource);
      return newDataSource;
    });
  };

  const handleAddSubtitle = (id: number): void => {
    setDataSource((prev: SubtitleInterface[]) => {
      const prevNewSubtitle = form.getFieldValue('subtitleList') || [];
      const newSubtitle = {
        id: id * -1,
        lang: '',
        file: undefined,
        created: new Date().toISOString(),
        modified: new Date().toISOString(),
        content_id: contentId,
        external_url: '',
      };
      form.setFieldValue('subtitleList', [...prevNewSubtitle, newSubtitle]);
      return [...prev, newSubtitle];
    });
  };

  const handleUpdateSubtitle = async (): Promise<void> => {
    const loadingMessage = message.loading('Subtitle upload...', 0);
    const subtitleList = form.getFieldValue('subtitleList');
    const filteredSubtitleList = subtitleList
      ? subtitleList.filter(
          (subtitle: SubtitleInterface) => subtitle.lang !== '' && subtitle.file !== undefined
        )
      : [];
    if (filteredSubtitleList) {
      try {
        await contentService.uploadSubtitle(contentId, filteredSubtitleList);
      } catch (error) {
        console.log(error);
      }
      message.success('upload success');
    }
    loadingMessage();
    form.resetFields(['subtitleList']);
  };

  const handleSubtitleDownload = async (subtitle: SubtitleInterface): Promise<void> => {
    try {
      // 우선 순위: external_url -> file (RcFile 객체) -> 에러
      const fileUrl = subtitle.external_url || subtitle.file;

      if (!fileUrl) {
        throw new Error('No valid file URL found for download');
      }

      const response = await fetch(fileUrl as string);

      if (!response.ok) {
        throw new Error('File fetch failed');
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;

      const fileName = (fileUrl as string).split('/').pop();

      link.download = fileName || 'downloaded-subtitle';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url); // 메모리 누수 방지
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return {
    handleLanguageChange,
    handleFileChange,
    handleActiveChange,
    handleAddSubtitle,
    handleUpdateSubtitle,
    handleSubtitleDownload,
  };
};

export default subtitleHandlers;

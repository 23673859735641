import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Checkbox, Form, Input, List, Select } from 'antd';
import { ThumbnailInterface } from 'content/type';
import React from 'react';
import { LANGUAGE_OPTIONS, ORIENTATION_OPTIONS } from 'constants/constant';

interface ThumbnailListProps {
  thumbnails: ThumbnailInterface[];
  selectedThumbnail: ThumbnailInterface | null;
  onThumbnailSelect: (thumbnail: ThumbnailInterface | null) => void;
  onThumbnailRemove: (thumbnail: ThumbnailInterface) => void;
  onThumbnailChange: (thumbnail: ThumbnailInterface, key: string, value: string) => void;
  onNewThumbnailChange: (thumbnail: ThumbnailInterface, key: string, value: string) => void;
  canDownload?: boolean;
  isNew?: boolean;
}

const ThumbnailList: React.FC<ThumbnailListProps> = ({
  thumbnails,
  selectedThumbnail,
  onThumbnailSelect,
  onThumbnailRemove,
  onThumbnailChange,
  onNewThumbnailChange,
  canDownload,
  isNew,
}) => {
  const handleThumbnailDownload = async (thumbnail: ThumbnailInterface): Promise<void> => {
    try {
      const fileUrl = thumbnail.file || thumbnail.originFileObj?.name;

      if (!fileUrl) {
        throw new Error('No valid file URL found for download');
      }

      const response = await fetch(fileUrl as string);

      if (!response.ok) {
        throw new Error('File fetch failed');
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;

      const fileName = fileUrl.split('/').pop();

      link.download = (fileName as string) || 'downloaded-thumbnail';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <List
      itemLayout="vertical"
      dataSource={thumbnails}
      renderItem={(thumbnail: ThumbnailInterface) => (
        <Card className="upload-video-list-item" hoverable>
          <div className="video-meta">
            <Form.Item>
              <Checkbox
                checked={selectedThumbnail?.id === thumbnail.id}
                onChange={e => {
                  if (e.target.checked) {
                    onThumbnailSelect(thumbnail);
                  } else {
                    onThumbnailSelect(null);
                  }
                }}
              />
            </Form.Item>
            <Avatar
              shape="square"
              style={{ width: '100px', height: '100px', paddingLeft: '10px' }}
              src={thumbnail.file ? thumbnail.file : ''}
            />
            <div className="video-info">
              <Form.Item label="Name">
                {canDownload ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <span
                      className="thumbnail-file-name"
                      title={thumbnail.file}
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                    >
                      {thumbnail.file?.split('/').pop()}
                    </span>
                    <Button
                      type="link"
                      icon={<DownloadOutlined />}
                      onClick={() => handleThumbnailDownload(thumbnail)}
                      style={{ padding: '0px' }}
                    >
                      Download
                    </Button>
                  </div>
                ) : (
                  <Input type="text" value={thumbnail.file || ''} />
                )}
                <Form.Item label="Language">
                  <Select
                    value={thumbnail.language}
                    onChange={(value: string) =>
                      isNew
                        ? onNewThumbnailChange(thumbnail, 'language', value)
                        : onThumbnailChange(thumbnail, 'language', value)
                    }
                    style={{ width: 170 }}
                    options={LANGUAGE_OPTIONS}
                  ></Select>
                </Form.Item>

                <Form.Item label="Orientation">
                  <Select
                    value={thumbnail.orientation}
                    onChange={(value: string) =>
                      isNew
                        ? onNewThumbnailChange(thumbnail, 'orientation', value)
                        : onThumbnailChange(thumbnail, 'orientation', value)
                    }
                    style={{ width: 120 }}
                    options={ORIENTATION_OPTIONS}
                  ></Select>
                </Form.Item>
              </Form.Item>
              <Button
                type="text"
                danger
                icon={<DeleteOutlined />}
                onClick={() => onThumbnailRemove(thumbnail)}
              >
                delete
              </Button>
            </div>
          </div>
        </Card>
      )}
    />
  );
};

export default ThumbnailList;

import { Flex, FormInstance, TableColumnsType } from 'antd';
import * as Component from 'components';
import { TransferDataSourceInterface } from 'components/transfer/Transfer';
import React from 'react';
import { getRolePermissionTransferHandlers } from './RolePermissionTransferHandlers';

type RolePermissionTransferProps = {
  id?: string;
  form: FormInstance;
  setRefreshKey: React.Dispatch<React.SetStateAction<number>>;
};

export const RolePermissionTransfer = ({
  id,
  form,
  setRefreshKey,
}: RolePermissionTransferProps): JSX.Element => {
  const { dataHandler, filterOption } = getRolePermissionTransferHandlers({
    id: id,
    form,
    setRefreshKey,
  });

  const columns: TableColumnsType<TransferDataSourceInterface> = [
    {
      dataIndex: 'id',
      title: 'Id',
      width: '10%',
    },
    {
      dataIndex: 'name',
      title: 'Name',
      render: (_, record): React.ReactNode => {
        return (
          <Flex align="center" gap="small">
            <span>{record.name}</span>
          </Flex>
        );
      },
    },
  ];

  return (
    <Component.TransferComponent
      dataHandler={dataHandler}
      filterOption={filterOption}
      columns={columns}
    />
  );
};

import { UserDetailType } from 'account/type/userType';
import { Button, Card, Form, Input, Select } from 'antd';
import { ACTION_TITLE } from 'constants/constant';
import { observer } from 'mobx-react';
import { roleService } from 'permission/service/roleService';
import { companyService } from 'project/company/service/companyService';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { userService } from '../../services/userService';
import { getUserEditHandlers } from './UserEditHandlers';

const UserEdit: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [user, setUser] = useState<UserDetailType | null>(null);
  const [companyList, setCompanyList] = useState<{ id: number; title: string }[]>([]);
  const [roleList, setRoleList] = useState<{ id: number; title: string }[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { handleOnClickEditButton } = getUserEditHandlers({ id: Number(id), form, navigate });

  useEffect(() => {
    const fetchUser = async (): Promise<void> => {
      const user = await userService.fetchById(Number(id));
      const roleList = await roleService.fetch();
      const roles = await userService.fetchRole(Number(id));
      const companyList = await companyService.fetchCompany();
      if (user) {
        setUser({ ...user, roles: roles.map(role => role.role) });
        const formattedUser = {
          ...user,
          platform: { id: user.platform.id, title: user.platform.title },
          role_ids: roles.map(role => role.role.id),
        };

        form.setFieldsValue(formattedUser);
        setCompanyList([...companyList, user.platform]);
        setRoleList(roleList.results.map(role => ({ id: role.id, title: role.name })));
      }
    };
    fetchUser().then(() => {
      setIsLoading(false);
    });
  }, [id]);

  return (
    <Component.BasePageComponent>
      {isLoading ? (
        <Component.LoadingSpinnerComponent />
      ) : (
        <div className="flex justify-center items-center min-h-screen bg-gray-100 p-6">
          <Card className="w-full max-w-lg p-6 shadow-lg rounded-2xl bg-white">
            <h3
              className="text-center mb-4 text-xl font-semibold"
              style={{ color: '#000', marginBottom: '30px' }}
            >
              Change User Information
            </h3>
            <Form form={form} onFinish={handleOnClickEditButton} layout="vertical">
              <Form.Item hidden name={['platform', 'id']} initialValue={user?.platform.id}>
                <Input />
              </Form.Item>
              <Form.Item label="Platform" required>
                <Input value={user?.platform.title} readOnly className="rounded-lg" />
              </Form.Item>
              <Form.Item
                name="username"
                label="ID"
                rules={[{ required: true, message: 'Please enter a username' }]}
              >
                <Input readOnly={true} className="rounded-lg" />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                initialValue={user?.email}
                rules={[
                  { required: true, message: 'Please enter an email' },
                  { type: 'email', message: 'Please enter a valid email address' },
                ]}
              >
                <Input className="rounded-lg" />
              </Form.Item>

              <div className="grid grid-cols-2 gap-4">
                <Form.Item name="first_name" label="First Name" initialValue={user?.first_name}>
                  <Input className="rounded-lg" />
                </Form.Item>
                <Form.Item name="last_name" label="Last Name" initialValue={user?.last_name}>
                  <Input className="rounded-lg" />
                </Form.Item>
              </div>
              <Form.Item
                name={['company', 'id']}
                label="Organization"
                initialValue={user?.company?.title}
              >
                <Select className="rounded-lg">
                  {companyList.map(company => (
                    <Select.Option value={company.id} key={company.id}>
                      {company.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="role_ids" label="Role">
                <Select mode="multiple">
                  {roleList.map(role => (
                    <Select.Option value={role.id} key={role.id}>
                      {role.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" className="w-full rounded-lg text-lg">
                  {ACTION_TITLE.EDIT}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      )}
    </Component.BasePageComponent>
  );
};

export default observer(UserEdit);

import { FormInstance, message } from 'antd';
import { companyService } from 'project/company/service/companyService';
import { CompanyInterface } from 'project/company/type/companyType';
import { NavigateFunction } from 'react-router-dom';
import { accountStore } from 'store/accountStore';

interface ContentProviderAddHandlersHook {
  form: FormInstance;
  navigate: NavigateFunction;
}

interface ContentProviderAddHandlersReturn {
  handleOnClickAddButton: () => Promise<void>;
}

export const getContentProviderAddHandlers = ({
  form,
  navigate,
}: ContentProviderAddHandlersHook): ContentProviderAddHandlersReturn => {
  const handleOnClickAddButton = async (): Promise<void> => {
    const loadingMessage = message.loading('Creating...', 0);
    try {
      const values = await form.validateFields();
      const user = accountStore.getAccount();
      const newCompany: CompanyInterface = {
        id: -1,
        title: values.title,
        description: values.description,
        title_en: values.title_en || undefined,
        slug: values.slug || undefined,
        country: values.country || undefined,
        platform_id: user?.platform.id || 1,
      };
      await companyService.create(newCompany);
      loadingMessage();
      navigate('/content-provider');
    } catch (error) {
      loadingMessage();
      message.error('Failed to create : ' + error);
    }
  };

  return {
    handleOnClickAddButton,
  };
};

import * as Component from 'components';
import React from 'react';
import { accountStore } from 'store/accountStore';
import * as ContentComponent from './components';
import './ContentEdit.css';

const ContentEdit: React.FC = () => {
  const videoComponents = [
    {
      key: 'detail',
      title: 'Detail',
      component: <ContentComponent.DetailComponent />,
    },
    {
      key: 'thumbnail',
      title: 'Thumbnail',
      component: <ContentComponent.ThumbnailComponent />,
    },
    {
      key: 'subtitle',
      title: 'Subtitle',
      component: <ContentComponent.SubtitleComponent />,
    },
    {
      key: 'transcode',
      title: 'Transcode',
      component: <ContentComponent.TranscodeComponent />,
    },
    {
      key: 'contract',
      title: 'Contract',
      component: <ContentComponent.ContractComponent />,
    },
    {
      key: 'revenue-share',
      title: 'Revenue Share',
      component: <ContentComponent.RevenueComponent />,
    },
    {
      key: 'service',
      title: 'Service',
      component: <ContentComponent.ServiceComponent />,
    },
  ];

  const userPlatform = accountStore.getAccount()!.platform;
  const keyList =
    userPlatform.id === 2
      ? ['detail', 'thumbnail', 'subtitle', 'dubbing', 'transcode']
      : [
          'detail',
          'thumbnail',
          'subtitle',
          'dubbing',
          'transcode',
          'contract',
          'revenue-share',
          'service',
        ];
  const filteredComponents = videoComponents.filter(component => keyList.includes(component.key));
  return (
    <div>
      <Component.BasePageComponent>
        <Component.TapComponent components={filteredComponents} />
      </Component.BasePageComponent>
    </div>
  );
};

export default ContentEdit;

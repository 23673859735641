import { FormInstance, message } from 'antd';
import { roleService } from 'permission/service/roleService';

type RoleAddHandler = {
  handleSave: () => void;
};

type FormValues = {
  name: string;
  permission?: number[];
  description?: string;
};

export const getRoleAddHandlers = ({ form }: { form: FormInstance }): RoleAddHandler => {
  const handleSave = async (): Promise<void> => {
    try {
      const values = (await form.validateFields()) as FormValues;
      const permissionIds = form.getFieldValue('permission') || [];
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('description', values.description || '');
      formData.append('permission_ids', permissionIds.join(','));

      await roleService.create(formData);
      message.success('Role has been created.');
    } catch (error) {
      message.error('An error occurred while saving.');
      console.error(error);
    }
  };

  return {
    handleSave,
  };
};

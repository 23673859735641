import { playlistService } from 'content/service';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { PlaylistInterface } from '../../type/playlistType';
import tableHandlers from './handlers/tableHandlers';
import './Playlist.css';
import { getPlaylistColumns } from './PlaylistColumns';

const Playlist: React.FC = () => {
  const navigate = useNavigate();
  const { handleOnClickTitle, handleOnClickSetting, onClickDeleteButton, handleAddPlaylistClick } =
    tableHandlers({
      navigate,
    });

  const columns = getPlaylistColumns({
    handleOnClickTitle,
    handleOnClickSetting,
    onClickDeleteButton,
  });

  const fetchPlaylist = useCallback(
    async (params: {
      page: number;
      pageSize: number;
      orderBy?: string;
      search?: string;
    }): Promise<{ data: PlaylistInterface[]; count: number }> => {
      const data = await playlistService.fetch({
        page: params.page.toString(),
        page_size: params.pageSize.toString(),
      });
      return { data: data.results, count: data.count };
    },
    []
  );

  return (
    <Component.BasePageComponent>
      <div className="playlist-container">
        <Component.TableComponent<PlaylistInterface>
          tableName="Playlist"
          dataFetch={fetchPlaylist}
          columns={columns}
          handleAddClick={handleAddPlaylistClick}
        />
      </div>
    </Component.BasePageComponent>
  );
};

export default observer(Playlist);

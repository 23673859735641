import { FormInstance, message } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import axios from 'axios';
import { DEFAULT_LANGUAGE, DEFAULT_ORIENTATION, THUMBNAIL_UPLOAD_STATE } from 'constants/index';
import { contentService } from 'content/service';
import { ThumbnailInterface, UploadThumbnailInterface } from 'content/type';

interface ThumbnailHandlersProps {
  form: FormInstance;
  contentId: number;
  selectedThumbnail: ThumbnailInterface | null;
  setNewThumbnailList: (thumbnailList: ThumbnailInterface[]) => void;
  setUploadedThumbnailList: (thumbnailList: ThumbnailInterface[]) => void;
  setSelectedThumbnail: (thumbnail: ThumbnailInterface | null) => void;
}

interface ThumbnailHandlers {
  handleChange: (info: UploadChangeParam<UploadFile<UploadThumbnailInterface>>) => void;
  handleNewThumbnailRemove: (thumbnail: ThumbnailInterface) => void;
  handleThumbnailUpload: () => void;
  handleUploadedThumbnailRemove: (thumbnail: ThumbnailInterface) => void;
  handleThumbnailSelect: (thumbnail: ThumbnailInterface | null) => void;
  handleThumbnailChange: (thumbnail: ThumbnailInterface, key: string, value: string) => void;
  handleNewThumbnailChange: (thumbnail: ThumbnailInterface, key: string, value: string) => void;
}

const createUploadThumbnailList = (
  uploadFileList: UploadFile<UploadThumbnailInterface>[]
): ThumbnailInterface[] => {
  let fileId = 0;
  return uploadFileList.map((file: UploadFile<UploadThumbnailInterface>): ThumbnailInterface => {
    const fileUrl = file.originFileObj ? URL.createObjectURL(file.originFileObj) : '';
    return {
      id: fileId++,
      file: fileUrl,
      is_representative: false,
      state: THUMBNAIL_UPLOAD_STATE.UPLOADING,
      originFileObj: file.originFileObj,
      orientation: DEFAULT_ORIENTATION,
      language: DEFAULT_LANGUAGE,
    };
  });
};

const handleChange =
  (props: ThumbnailHandlersProps) =>
  (info: UploadChangeParam<UploadFile<UploadThumbnailInterface>>): void => {
    const { form, setNewThumbnailList } = props;
    const uploadFileList = info.fileList || [];
    const thumbnailList = form.getFieldValue('newThumbnailList') || [];
    const uploadThumbnailList = createUploadThumbnailList(uploadFileList);

    const newThumbnailList = [...uploadThumbnailList, ...thumbnailList];
    console.log(newThumbnailList);
    console.log(setNewThumbnailList);
    form.setFieldValue(
      'newThumbnailList',
      newThumbnailList.map(thumbnail => ({
        id: thumbnail.id,
        file: thumbnail.originFileObj,
        language: thumbnail.language,
        orientation: thumbnail.orientation,
      }))
    );
    setNewThumbnailList(newThumbnailList);
  };

const handleNewThumbnailRemove =
  (props: ThumbnailHandlersProps) =>
  (removedThumbnail: ThumbnailInterface): void => {
    const { form, setNewThumbnailList } = props;
    const newThumbnailList = form.getFieldValue('newThumbnailList') || [];

    const tempNewThumbnailList = newThumbnailList.filter(
      (thumbnail: { id: number }) => thumbnail.id !== removedThumbnail.id
    );

    form.setFieldValue('newThumbnailList', tempNewThumbnailList);
    setNewThumbnailList(tempNewThumbnailList);
  };

const handleUploadedThumbnailRemove =
  (props: ThumbnailHandlersProps) =>
  (removedThumbnail: ThumbnailInterface): void => {
    const { form, setUploadedThumbnailList } = props;
    const uploadedThumbnailList = form.getFieldValue('uploadedThumbnailList') || [];

    const tempUploadedThumbnailList = uploadedThumbnailList.filter(
      (thumbnail: { id: number }) => thumbnail.id !== removedThumbnail.id
    );

    form.setFieldValue('uploadedThumbnailList', tempUploadedThumbnailList);
    setUploadedThumbnailList(tempUploadedThumbnailList);
  };

const handleThumbnailSelect =
  (props: ThumbnailHandlersProps) =>
  (newSelectedThumbnail: ThumbnailInterface | null): void => {
    const { form, selectedThumbnail, setSelectedThumbnail } = props;
    if (selectedThumbnail) {
      selectedThumbnail.is_representative = false;
    }
    if (newSelectedThumbnail) {
      newSelectedThumbnail.is_representative = true;
    }
    form.setFieldValue('selectedThumbnail', newSelectedThumbnail);
    setSelectedThumbnail(newSelectedThumbnail);
  };

const handleThumbnailUpload = (props: ThumbnailHandlersProps) => async (): Promise<void> => {
  const { form, contentId } = props;
  const loadingMessage = message.loading('Updating...', 0);
  const thumbnailList = form.getFieldValue('thumbnailList');
  const selectedThumbnail = form.getFieldValue('selectedThumbnail');
  const newThumbnailList = form.getFieldValue('newThumbnailList');
  const uploadedThumbnailList = form.getFieldValue('uploadedThumbnailList');

  if (selectedThumbnail) {
    newThumbnailList.map((file: { id: number; is_representative: boolean }) => {
      if (file.id === selectedThumbnail.id) {
        file.is_representative = selectedThumbnail.is_representative;
        selectedThumbnail.id = -1;
      }
      return file;
    });
  }
  const removeThumbnails = thumbnailList.filter(
    (uploadedThumbnail: { id: number }) =>
      !uploadedThumbnailList.some(
        (uploadThumbnail: { id: number }) => uploadThumbnail.id === uploadedThumbnail.id
      )
  );
  const changedThumbnails = uploadedThumbnailList.filter((updated: ThumbnailInterface) => {
    const original = thumbnailList.find((t: ThumbnailInterface) => t.id === updated.id);
    return (
      original &&
      (original.language !== updated.language ||
        original.orientation !== updated.orientation ||
        original.is_representative !== updated.is_representative)
    );
  });

  try {
    if (newThumbnailList.length > 0) {
      await contentService.uploadThumbnail(contentId, newThumbnailList);
    }
    if (removeThumbnails.length > 0) {
      await contentService.removeThumbnail(contentId, removeThumbnails);
    }
    if (changedThumbnails.length > 0) {
      for (const thumbnail of changedThumbnails) {
        await contentService.updateThumbnail(contentId, thumbnail);
      }
    }
    if (selectedThumbnail && selectedThumbnail.id !== -1) {
      await contentService.updateThumbnail(contentId, selectedThumbnail);
    }
    loadingMessage();
    message.success('upload success');
    form.resetFields([
      'newThumbnailList',
      'uploadedThumbnailList',
      'selectedThumbnail',
      'thumbnailList',
    ]);
  } catch (error) {
    loadingMessage();
    if (axios.isAxiosError(error) && error.response?.status === 400) {
      message.error('Failed to update : ' + JSON.stringify(error.response.data));
    }
  }
};

const handleNewThumbnailChange =
  (props: ThumbnailHandlersProps) =>
  (targetThumbnail: UploadThumbnailInterface, key: string, value: string): void => {
    const { form, setNewThumbnailList } = props;
    const newThumbnailList = form.getFieldValue('newThumbnailList') || [];

    const updatedList = newThumbnailList.map((thumbnail: UploadThumbnailInterface) => {
      if (thumbnail.id !== targetThumbnail.id) return thumbnail;

      const updated: UploadThumbnailInterface = {
        ...thumbnail,
        [key]: value,
      };

      return updated;
    });

    // ✅ 1. form 값에는 원본 그대로 저장 (originFileObj 유지)
    form.setFieldValue('newThumbnailList', updatedList);

    // ✅ 2. 화면 렌더링용은 file을 blob URL로 보장
    const renderList = updatedList.map((thumbnail: UploadThumbnailInterface) => ({
      ...thumbnail,
      file:
        typeof thumbnail.file !== 'string' && thumbnail.file
          ? URL.createObjectURL(thumbnail.file)
          : thumbnail.file,
    }));

    setNewThumbnailList(renderList);
  };

const handleThumbnailChange =
  (props: ThumbnailHandlersProps) =>
  (targetThumbnail: ThumbnailInterface, key: string, value: string): void => {
    const { form, setUploadedThumbnailList } = props;
    const uploadedThumbnailList = form.getFieldValue('uploadedThumbnailList') || [];

    const updatedList = uploadedThumbnailList.map((thumbnail: ThumbnailInterface) =>
      thumbnail.id === targetThumbnail.id ? { ...thumbnail, [key]: value } : thumbnail
    );

    form.setFieldValue('uploadedThumbnailList', updatedList);
    setUploadedThumbnailList(updatedList);
  };

const thumbnailHandlers = (props: ThumbnailHandlersProps): ThumbnailHandlers => ({
  handleChange: handleChange(props),
  handleNewThumbnailRemove: handleNewThumbnailRemove(props),
  handleUploadedThumbnailRemove: handleUploadedThumbnailRemove(props),
  handleThumbnailSelect: handleThumbnailSelect(props),
  handleThumbnailUpload: handleThumbnailUpload(props),
  handleThumbnailChange: handleThumbnailChange(props),
  handleNewThumbnailChange: handleNewThumbnailChange(props),
});

export default thumbnailHandlers;

import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { userService } from '../../services/userService';
import { UserDetailType } from '../../type/userType';
import { getUserColumns } from './UserColumns';
import { getUserTableHandlers } from './UserHandlers';

const UserPage: React.FC = () => {
  const navigate = useNavigate();
  const { onClickEditButton, onClickDeleteButton, handleAddUserClick } = getUserTableHandlers({
    navigate,
  });

  const columns = getUserColumns({
    onClickEditButton,
    onClickDeleteButton,
  });

  const fetchUser = async (params: {
    page: number;
    pageSize: number;
    orderBy?: string;
    search?: string;
  }): Promise<{ data: UserDetailType[]; count: number }> => {
    const userList = await userService.fetch({
      page: params.page.toString(),
      page_size: params.pageSize.toString(),
    });

    const userListWithRole = await Promise.all(
      userList.results.map(async user => {
        const roles = await userService.fetchRole(user.id);
        return { ...user, roles: roles.map(role => role.role) };
      })
    );
    return { data: userListWithRole, count: userList.count };
  };

  return (
    <Component.BasePageComponent>
      <Component.TableComponent<UserDetailType>
        tableName="User"
        columns={columns}
        dataFetch={fetchUser}
        handleAddClick={handleAddUserClick}
      />
    </Component.BasePageComponent>
  );
};

export default observer(UserPage);

import { FormInstance } from 'antd';
import { CATEGORY_API_PATH } from 'constants/api';
import { CategoryInterface } from 'content/type';
import { ApiService } from 'service/apiService';
import { FetchResult } from 'type/apiType';

class CategoryService {
  constructor(private apiService: ApiService<CategoryInterface>) {}

  public async fetch(): Promise<CategoryInterface[]> {
    const category = await this.apiService.fetch({ page_size: '100' });
    return category.results;
  }

  public async fetchById(id: number): Promise<CategoryInterface> {
    const category = await this.apiService.fetchById(id);
    return category;
  }

  public async fetchByIds(ids: number[], pageSize: number = 100): Promise<CategoryInterface[]> {
    const categories = await this.apiService.fetchByIds(ids, {
      page_size: String(pageSize),
    });
    return categories.results;
  }

  public async create(form: FormInstance): Promise<CategoryInterface> {
    const category = form.getFieldsValue();
    category.name = category.name.toLowerCase();
    return await this.apiService.create(category);
  }

  public async update(id: number, form: FormInstance): Promise<CategoryInterface> {
    const category = form.getFieldsValue();
    category.name = category.name.toLowerCase();
    return await this.apiService.update(id, category);
  }

  public async fetchCategoryList(
    page: number,
    pageSize: number
  ): Promise<FetchResult<CategoryInterface>> {
    return await this.apiService.fetch({
      page: page.toString(),
      page_size: pageSize.toString(),
    });
  }
}

export const categoryService = new CategoryService(new ApiService(CATEGORY_API_PATH));

import { COMPANY_API_PATH } from 'constants/api';
import { ApiService } from 'service/apiService';
import { accountStore } from 'store/accountStore';
import { FetchResult } from 'type/apiType';
import { CompanyInterface } from '../type/companyType';

export class CompanyService {
  constructor(private apiService: ApiService<CompanyInterface>) {}

  public async create(company: CompanyInterface): Promise<CompanyInterface> {
    return await this.apiService.create(company);
  }

  public async update(id: number, company: CompanyInterface): Promise<CompanyInterface> {
    return await this.apiService.update(id, company);
  }

  public async fetch(queryParams?: Record<string, string>): Promise<FetchResult<CompanyInterface>> {
    const platform = accountStore.getAccount()!.platform;
    return await this.apiService.fetch({ ...queryParams, platform_id: platform.id.toString() });
  }

  public async fetchById(id: number): Promise<CompanyInterface> {
    const response = await this.apiService.fetchById(id);
    return response;
  }

  public async fetchCompany(): Promise<CompanyInterface[]> {
    const pageSize = 100;
    const companyList: CompanyInterface[] = [];
    let page = 1;
    let pageNext = true;
    while (pageNext) {
      const queryParams = {
        page_size: pageSize.toString(),
        page: page.toString(),
      };
      const platform = accountStore.getAccount()!.platform;
      const result = await this.apiService.fetch({
        ...queryParams,
        platform_id: platform.id.toString(),
      });
      pageNext = result.next !== null;
      page = page + 1;
      companyList.push(...result.results);
    }
    return companyList;
  }
}

export const companyService = new CompanyService(new ApiService(COMPANY_API_PATH));

import { FormInstance } from 'antd';
import { seriesService } from 'content/service';
import { SeriesInterface } from 'content/type/seriesType';
import { NavigateFunction } from 'react-router-dom';

interface UseSeriesDetailProps {
  form: FormInstance;
  navigate: NavigateFunction;
  selectedCategories: number[];
  setIsModalVisible: (value: boolean) => void;
}

interface DetailHandlers {
  handleOnClickAddButtonClick: () => Promise<void>;
  handleOnClickEditButtonClick: () => Promise<void>;
  handleEditCategories: () => void;
  handleModalOk: () => void;
  handleModalCancel: () => void;
}

const handleOnClickAddButtonClick = (props: UseSeriesDetailProps) => async (): Promise<void> => {
  const { form, navigate, selectedCategories } = props;
  const newSeries: SeriesInterface = {
    id: -1,
    title: form.getFieldValue('title'),
    ...(form.getFieldValue('synopsis') && { synopsis: form.getFieldValue('synopsis') }),
    title_en: form.getFieldValue('title_en'),

    ...(form.getFieldValue('season_number') && {
      season_number: form.getFieldValue('season_number'),
    }),

    ...(form.getFieldValue('release_date_start') && {
      release_date_start: form.getFieldValue('release_date_start'),
    }),

    ...(form.getFieldValue('release_date_end') && {
      release_date_end: form.getFieldValue('release_date_end'),
    }),
    ...(form.getFieldValue('produce_country') && {
      produce_country: form.getFieldValue('produce_country'),
    }),
    ...(form.getFieldValue('cast') && { cast: form.getFieldValue('cast') }),
    categories: selectedCategories,
  };

  await seriesService.create(newSeries);
  navigate('/series');
};

const handleOnClickEditButtonClick = (props: UseSeriesDetailProps) => async (): Promise<void> => {
  const { form, navigate, selectedCategories } = props;
  const id = form.getFieldValue('id');
  const newSeries: SeriesInterface = {
    id: Number(id),
    title: form.getFieldValue('title'),
    title_en: form.getFieldValue('title_en'),
    age_rating: form.getFieldValue('age_rating'),
    ...(form.getFieldValue('synopsis') && { synopsis: form.getFieldValue('synopsis') }),
    ...(form.getFieldValue('release_date_start') && {
      release_date_start: form.getFieldValue('release_date_start'),
    }),
    ...(form.getFieldValue('release_date_end') && {
      release_date_end: form.getFieldValue('release_date_end'),
    }),
    ...(form.getFieldValue('produce_country') && {
      produce_country: form.getFieldValue('produce_country'),
    }),
    ...(form.getFieldValue('cast') && { cast: form.getFieldValue('cast') }),
    categories: selectedCategories,
  };

  await seriesService.update(Number(id), newSeries);
  navigate('/series');
};

const handleEditCategories = (props: UseSeriesDetailProps) => (): void => {
  const { setIsModalVisible } = props;
  setIsModalVisible(true);
};

const handleModalOk = (props: UseSeriesDetailProps) => (): void => {
  const { form, selectedCategories, setIsModalVisible } = props;
  form.setFieldsValue({ categories: selectedCategories });
  setIsModalVisible(false);
};

const handleModalCancel = (props: UseSeriesDetailProps) => (): void => {
  const { setIsModalVisible } = props;
  setIsModalVisible(false);
};

const detailHandlers = (props: UseSeriesDetailProps): DetailHandlers => ({
  handleOnClickAddButtonClick: handleOnClickAddButtonClick(props),
  handleOnClickEditButtonClick: handleOnClickEditButtonClick(props),
  handleEditCategories: handleEditCategories(props),
  handleModalOk: handleModalOk(props),
  handleModalCancel: handleModalCancel(props),
});

export default detailHandlers;

import { Button, Space, Tag } from 'antd';
import { RoleType } from 'permission/type/roleType';
import React from 'react';
import { TableColumnInterface } from '../../../../shared/components/table/Table';
import { UserDetailType } from '../../type/userType';

interface UserColumnsProps {
  onClickEditButton: (id: number) => void;
  onClickDeleteButton: (id: number) => void;
}

export const getUserColumns = ({
  onClickEditButton,
  onClickDeleteButton,
}: UserColumnsProps): TableColumnInterface<UserDetailType>[] => {
  return [
    {
      title: 'User ID',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Organization',
      dataIndex: 'company',
      key: 'company_id',
      render: (_text: string, record: UserDetailType): React.ReactNode => {
        return <div>{record.company?.title || '-'}</div>;
      },
    },
    {
      title: 'Role',
      dataIndex: 'roles',
      key: 'roles',
      render: (_text: string, record: UserDetailType): React.ReactNode => {
        return (
          <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
            {record.roles.map((role: RoleType) => (
              <Tag key={role.id} color="blue">
                {role.name}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: 'Actions',
      key: 'action',
      dataIndex: 'action',
      render: (_text: string, record: UserDetailType): React.ReactNode => (
        <Space size="middle">
          <Button key="edit" onClick={() => onClickEditButton(record.id)}>
            Edit
          </Button>
          <Button key="delete" onClick={() => onClickDeleteButton(record.id)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];
};

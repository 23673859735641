import * as Component from 'components';
import { contentService } from 'content/service';
import { ContentInterface } from 'content/type';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { accountStore } from 'store/accountStore';
import './Content.css';
import { getTableColumn } from './ContentColumn';
import { contentHandlers } from './handlers';

const Content: React.FC = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState<ContentInterface | null>(null);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [filterValue, setFilterValue] = React.useState<string>('');
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [selectedList, setSelectedList] = useState<number[]>([]);
  const user = accountStore.getAccount();
  const isPlatformOne = user?.platform.id === 1;

  const {
    onClickDeleteButton,
    handleOnClickTitle,
    handleOnClickSetting,
    handleCancelModal,
    handleShowModal,
    handleBulkDelete,
    handleOnDownload,
    handleAddVideoClick,
  } = contentHandlers({
    navigate,
    setIsModalVisible,
    setSelectedRecord,
  });

  const handleBulkDistribution = async () => {
    if (selectedList.length === 0) {
      alert('Please select at least one content');
      return;
    }
    try {
      await contentService.bulkDistribution(selectedList, 'amasian');
      setRefreshCounter(prev => prev + 1);
      setSelectedList([]);
    } catch (error) {
      console.error('Failed to add distribution:', error);
      alert('Failed to add distribution');
    }
  };

  const columns = getTableColumn({
    isModalVisible,
    selectedRecord,
    handleOnClickTitle,
    handleOnClickSetting,
    handleCancelModal,
    handleShowModal,
    onClickDeleteButton,
    handleOnDownload,
  });

  const fetchContent = useCallback(
    async (params: {
      page: number;
      pageSize: number;
      orderBy?: string;
      search?: string;
    }): Promise<{ data: ContentInterface[]; count: number }> => {
      const contentList = await contentService.fetch({
        page: params.page.toString(),
        page_size: params.pageSize.toString(),
        search: searchTerm,
        ...(filterValue && { distribution: filterValue }),
      });
      return { data: contentList.results, count: contentList.count };
    },
    [searchTerm, filterValue]
  );

  useEffect(() => {
    if (filterValue) {
      setRefreshCounter(prev => prev + 1);
    }
  }, [filterValue]);

  return (
    <Component.BasePageComponent>
      <div className="video-content-container">
        <Component.TableComponent<ContentInterface>
          key={refreshCounter}
          tableName="Content"
          searchTerm={searchTerm}
          dataFetch={fetchContent}
          columns={columns}
          handleSearchTerm={setSearchTerm}
          handleAddClick={handleAddVideoClick}
          handleBulkDelete={handleBulkDelete}
          filterOptions={
            isPlatformOne
              ? [
                  {
                    label: 'Amasian',
                    value: 'amasian',
                  },
                ]
              : undefined
          }
          handleFilterChange={isPlatformOne ? setFilterValue : undefined}
          allowClearFilter={isPlatformOne}
          filterValue={filterValue}
          onSelectionChange={setSelectedList}
          optionalButton={
            isPlatformOne
              ? [
                  <Component.ColorButtonComponent
                    key="bulk-distribution"
                    text="+ Add Amasian Distribution"
                    onClick={handleBulkDistribution}
                    size="small"
                  />,
                ]
              : undefined
          }
        />
      </div>
    </Component.BasePageComponent>
  );
};

export default observer(Content);

import { Form } from 'antd';
import { categoryService, contentService, seasonService } from 'content/service';

import { CategoryInterface, SeasonInterface } from 'content/type';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import * as SeasonComponent from './components';
// import { detailHandlers } from './handlers';

const SeasonEdit: React.FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [, setLoading] = useState(true);
  // const navigate = useNavigate();
  const [season, setSeason] = useState<SeasonInterface | null>(null);
  const [categoryList, setCategoryList] = useState<CategoryInterface[]>([]);

  // const { handleOnClickEditButtonClick } = detailHandlers({ form, navigate, id: Number(id) });

  useEffect(() => {
    const fetchSeason = async (): Promise<void> => {
      const season: SeasonInterface = await seasonService.fetchById(Number(id));
      if (season) {
        setSeason(season);
        const formattedSeason = {
          ...season,
          release_date_start: season.release_date_start,
          release_date_end: season.release_date_end,
        };

        const fetchedCategories = await categoryService.fetchByIds(season.categories || []);
        setCategoryList(fetchedCategories);

        if (season.contents) {
          const contents = await contentService.fetchByIds(season.contents.join(','));
          form.setFieldValue(
            'contents',
            contents.results.map(content => {
              return { ...content, name: content.title };
            }) || []
          );
        }
        form.setFieldsValue(formattedSeason);
      }
      setLoading(false);
    };
    fetchSeason().then(() => {
      setLoading(false);
    });
  }, [id, form]);

  const components = [
    {
      key: 'detail',
      title: 'Detail',
      component: (
        <SeasonComponent.SeasonDetailComponent
          form={form}
          season={season || undefined}
          contentCategoryList={categoryList || undefined}
          actionTitle="SAVE"
          // handleOnClickButtonClick={handleOnClickEditButtonClick}
        />
      ),
    },
    {
      key: 'poster',
      title: 'Poster',
      component: (
        <SeasonComponent.PosterComponent
          seasonId={Number(id)}
          uploadedPosterList={season?.posters || []}
        />
      ),
    },
    {
      key: 'content',
      title: 'Content',
      component: <SeasonComponent.ContentComponent seasonId={Number(id)} />,
    },
  ];

  return (
    <Component.BasePageComponent>
      <Component.TapComponent components={components} />
    </Component.BasePageComponent>
  );
};

export default observer(SeasonEdit);

import { UploadOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { ColumnType } from 'antd/es/table';
import Upload, { RcFile } from 'antd/es/upload';
import { LANGUAGE_LIST, SUBTITLE_FILE_EXTENSION } from 'constants/index';
import { SubtitleInterface } from 'content/type';
import React from 'react';

interface SubtitleColumnsProps {
  handleLanguageChange: (value: string, record: { id: string }) => void;
  handleFileChange: (file: RcFile, record: { id: string }) => void;
  handleSubtitleDownload: (subtitle: SubtitleInterface) => void;
}

export const getSubtitleColumns = ({
  handleLanguageChange,
  handleFileChange,
  handleSubtitleDownload,
}: SubtitleColumnsProps): ColumnType<SubtitleInterface>[] => {
  return [
    {
      title: 'Lang',
      dataIndex: 'lang',
      key: 'lang',
      width: '10%',
      render: (_text: string, record: SubtitleInterface) => (
        <Select
          options={LANGUAGE_LIST.map(language => ({ label: language, value: language }))}
          style={{ width: '100%', marginBottom: '10px' }}
          onChange={value => handleLanguageChange(value, { id: record.id.toString() })}
          value={record.lang}
        />
      ),
    },
    {
      title: 'File',
      dataIndex: 'file',
      key: 'file',
      render: (_file: RcFile | undefined, record: SubtitleInterface): React.ReactNode => {
        const isUploadedFile = typeof record.file === 'string' || record.external_url;
        const fileName = isUploadedFile
          ? (record.file as unknown as string).split('/').pop()
          : (record.file as RcFile)?.name;

        return isUploadedFile ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <span
              style={{
                flex: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {fileName}
            </span>
            <Button
              type="link"
              onClick={() => handleSubtitleDownload(record)}
              style={{ padding: 0 }}
            >
              Download
            </Button>
          </div>
        ) : (
          <Upload
            key={record.id.toString()}
            accept={SUBTITLE_FILE_EXTENSION.join(',')}
            beforeUpload={() => false} // 자동 업로드 방지
            onChange={info => handleFileChange(info.file as RcFile, { id: record.id.toString() })}
            listType="text"
            maxCount={1}
          >
            <Button icon={<UploadOutlined />} style={{ width: '100%' }}>
              Subtitle file upload
            </Button>
          </Upload>
        );
      },
    },
  ];
};

import { FormInstance, message } from 'antd';
import { seasonService } from 'content/service';
import { SeasonInterface } from 'content/type/seasonType';
import { NavigateFunction } from 'react-router-dom';

interface UseSeasonDetailProps {
  form: FormInstance;
  navigate: NavigateFunction;
  selectedCategories: number[];
  setIsModalVisible: (value: boolean) => void;
}

interface DetailHandlers {
  handleOnClickAddButtonClick: () => Promise<void>;
  handleOnClickEditButtonClick: () => Promise<void>;
  handleEditCategories: () => void;
  handleModalOk: () => void;
  handleModalCancel: () => void;
}

const handleOnClickAddButtonClick = (props: UseSeasonDetailProps) => async (): Promise<void> => {
  const { form, navigate, selectedCategories } = props;
  const loadingMessage = message.loading('Creating...', 0);
  try {
    const newSeason: SeasonInterface = {
      id: -1,
      title: form.getFieldValue('title'),
      ...(form.getFieldValue('synopsis') && { synopsis: form.getFieldValue('synopsis') }),
      title_en: form.getFieldValue('title_en'),

      ...(form.getFieldValue('season_number') && {
        season_number: form.getFieldValue('season_number'),
      }),

      ...(form.getFieldValue('release_date_start') && {
        release_date_start: form.getFieldValue('release_date_start'),
      }),

      ...(form.getFieldValue('release_date_end') && {
        release_date_end: form.getFieldValue('release_date_end'),
      }),
      ...(form.getFieldValue('produce_country') && {
        produce_country: form.getFieldValue('produce_country'),
      }),
      ...(form.getFieldValue('cast') && { cast: form.getFieldValue('cast') }),
      categories: selectedCategories,
    };

    await seasonService.create(newSeason);
    loadingMessage();
    navigate('/season');
  } catch (error) {
    loadingMessage();
    message.error('Failed to create : ' + error);
  }
};

const handleOnClickEditButtonClick = (props: UseSeasonDetailProps) => async (): Promise<void> => {
  const loadingMessage = message.loading('Updating...', 0);
  const { form, navigate, selectedCategories } = props;
  const id = form.getFieldValue('id');
  try {
    const newSeasons: SeasonInterface = {
      id: Number(id),
      title: form.getFieldValue('title'),
      title_en: form.getFieldValue('title_en'),
      age_rating: form.getFieldValue('age_rating'),
      ...(form.getFieldValue('synopsis') && { synopsis: form.getFieldValue('synopsis') }),
      ...(form.getFieldValue('release_date_start') && {
        release_date_start: form.getFieldValue('release_date_start'),
      }),
      ...(form.getFieldValue('release_date_end') && {
        release_date_end: form.getFieldValue('release_date_end'),
      }),
      ...(form.getFieldValue('produce_country') && {
        produce_country: form.getFieldValue('produce_country'),
      }),
      ...(form.getFieldValue('cast') && { cast: form.getFieldValue('cast') }),
      categories: selectedCategories,
    };

    await seasonService.update(Number(id), newSeasons);
    loadingMessage();
    navigate('/season');
  } catch (error) {
    loadingMessage();
    message.error('Failed to update : ' + error);
  }
};

const handleEditCategories = (props: UseSeasonDetailProps) => (): void => {
  const { setIsModalVisible } = props;
  setIsModalVisible(true);
};

const handleModalOk = (props: UseSeasonDetailProps) => (): void => {
  const { form, selectedCategories, setIsModalVisible } = props;
  form.setFieldsValue({ categories: selectedCategories });
  setIsModalVisible(false);
};

const handleModalCancel = (props: UseSeasonDetailProps) => (): void => {
  const { setIsModalVisible } = props;
  setIsModalVisible(false);
};

const detailHandlers = (props: UseSeasonDetailProps): DetailHandlers => ({
  handleOnClickAddButtonClick: handleOnClickAddButtonClick(props),
  handleOnClickEditButtonClick: handleOnClickEditButtonClick(props),
  handleEditCategories: handleEditCategories(props),
  handleModalOk: handleModalOk(props),
  handleModalCancel: handleModalCancel(props),
});

export default detailHandlers;

import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { accountStore } from 'store/accountStore';
import * as Component from '../../../../shared/components';
import { videoSourceService } from '../service/videoSourceService';
import { VideoSourceInterface } from '../type/videoSourceType';
import './VideoSource.css';
import { getVideoSourceColumns } from './VideoSourceColumns';
import { getVideoSourceHandlers } from './VideoSourceHandlers';

const VideoSourcePage: React.FC = () => {
  const navigate = useNavigate();
  const { onClickTitleButton, onClickSettingButton, onClickDeleteButton, onClickReloadButton } =
    getVideoSourceHandlers({ navigate });

  const columns = getVideoSourceColumns({
    onClickTitleButton,
    onClickSettingButton,
    onClickDeleteButton,
    onClickReloadButton,
  });

  const fetchVideoSource = async (params: {
    page: number;
    pageSize: number;
    orderBy?: string;
    search?: string;
  }): Promise<{ data: VideoSourceInterface[]; count: number }> => {
    const videoSourceList = await videoSourceService.fetch({
      page: params.page.toString(),
      page_size: params.pageSize.toString(),
    });
    const user = accountStore.getAccount();
    const platform = user?.platform;
    const filteredVideoSourceList = videoSourceList.results.filter(
      source => source.name !== 'ODK' && source.platform === platform?.id
    );

    return { data: filteredVideoSourceList, count: videoSourceList.count };
  };

  return (
    <Component.BasePageComponent>
      <Component.TableComponent<VideoSourceInterface>
        tableName="VideoSource"
        dataFetch={fetchVideoSource}
        columns={columns}
      />
    </Component.BasePageComponent>
  );
};

export default observer(VideoSourcePage);

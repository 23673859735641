import axios from 'axios';
import { BASE_URL, VIDEO_SOURCE_API_PATH } from 'constants/api';
import { contentService } from 'content/service';
import { ContentInterface } from 'content/type';
import { ApiService } from 'service/apiService';
import { FetchResult } from 'type/apiType';
import {
  VideoSourceInterface,
  VideoSourceSelectItemInterface,
  VideoSourceVideoListInterface,
} from '../type';

class VideoSourceService {
  constructor(private readonly apiService: ApiService<VideoSourceInterface>) {}

  public async fetch(
    queryParams: Record<string, string>
  ): Promise<FetchResult<VideoSourceInterface>> {
    return await this.apiService.fetch(queryParams);
  }

  public async fetchById(id: number): Promise<VideoSourceInterface> {
    return await this.apiService.fetchById(id);
  }

  public async create(videoSource: VideoSourceInterface): Promise<void> {
    await this.apiService.create(videoSource);
  }

  public async update(id: number, videoSource: VideoSourceInterface): Promise<void> {
    await this.apiService.update(id, videoSource);
  }

  public async bulkOdkSync(startFromBeginning: boolean): Promise<void> {
    return await axios.post(`${BASE_URL}${VIDEO_SOURCE_API_PATH}odk-bulk/`, {
      reset: startFromBeginning,
    });
  }

  public async getODKItem(): Promise<VideoSourceInterface> {
    const response = await axios.get(`${BASE_URL}${VIDEO_SOURCE_API_PATH}odk/`);
    return response.data;
  }

  public async generate(id: number): Promise<void> {
    await axios.post(`${BASE_URL}${VIDEO_SOURCE_API_PATH}${id}/generate/`);
  }

  public async delete(id: number): Promise<void> {
    await this.apiService.delete(id);
  }

  public async getVideoSourceSelectItemList(): Promise<VideoSourceSelectItemInterface[]> {
    const items = await this.apiService.fetch();
    return items.results.map(item => ({
      id: item.id,
      name: item.name,
    }));
  }

  public async fetchContentList(
    id: number,
    queryParams: Record<string, string>
  ): Promise<VideoSourceVideoListInterface[]> {
    if (id === 0) {
      const { results: videoList } = await contentService.fetch(queryParams);
      // TODO: 현재는 Content Item List를 가져오는 것이 아니라, 비디오 리스트를 가져오는 것이라서 이렇게 처리함.
      // 추후 Content Item List를 가져오는 것으로 변경 필요
      return videoList.map((item: ContentInterface) => ({
        id: item.id,
        guid: 0,
        original_content_url: '',
        status: 'completed',
        video_source: 0,
        content: item,
      })) as VideoSourceVideoListInterface[];
    }

    const response = await axios.get(`${BASE_URL}${VIDEO_SOURCE_API_PATH}${id}/video-list/`, {
      params: queryParams,
    });
    return response ? [] : [];
    // return response.data ? response.data.results : [];
  }
}

export const videoSourceService = new VideoSourceService(new ApiService(VIDEO_SOURCE_API_PATH));

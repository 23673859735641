import { seasonService } from 'content/service';
import { SeasonInterface } from 'content/type';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import * as Component from '../../../../shared/components';
import { tableHandlers } from './handlers';
import { getSeasonColumns } from './SeasonColumns';

const Season: React.FC = () => {
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [refreshCounter, setRefreshCounter] = useState(0);
  const { handleOnClickTitle, handleOnClickSetting, onClickDeleteButton, handleAddSeasonClick } =
    tableHandlers();
  const columns = getSeasonColumns({
    handleOnClickTitle,
    handleOnClickSetting,
    onClickDeleteButton,
  });

  const fetchSeason = useCallback(
    async (params: {
      page: number;
      pageSize: number;
      orderBy?: string;
      search?: string;
    }): Promise<{ data: SeasonInterface[]; count: number }> => {
      const seasonList = await seasonService.fetch({
        page: params.page.toString(),
        page_size: params.pageSize.toString(),
        search: searchTerm,
      });
      return { data: seasonList.results, count: seasonList.count };
    },
    [searchTerm]
  );

  useEffect(() => {
    setRefreshCounter(prev => prev + 1);
  }, [searchTerm]);

  return (
    <Component.BasePageComponent>
      <Component.TableComponent<SeasonInterface>
        key={refreshCounter}
        tableName="Season"
        searchTerm={searchTerm}
        dataFetch={fetchSeason}
        columns={columns}
        handleAddClick={handleAddSeasonClick}
        handleSearchTerm={setSearchTerm}
      />
    </Component.BasePageComponent>
  );
};

export default observer(Season);

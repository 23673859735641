import { TablePaginationConfig } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { SorterResult } from 'antd/es/table/interface';
import { UseTableReturn } from 'type/tableType';

export const useTable = <T>({
  dataSource,
  setCurrent,
  setPageSize,
  setSelectedList,
  setSelectAll,
  setOrder,
  setOrderByField,
}: {
  dataSource: T[];
  setCurrent: (current: number) => void;
  setPageSize: (pageSize: number) => void;
  setSelectedList: React.Dispatch<React.SetStateAction<number[]>>;
  setSelectAll: React.Dispatch<React.SetStateAction<boolean>>;
  setOrder: React.Dispatch<React.SetStateAction<string>>;
  setOrderByField: React.Dispatch<React.SetStateAction<string>>;
}): UseTableReturn<T> => {
  const onPageChange = async (
    pagination: TablePaginationConfig,
    prePagination: TablePaginationConfig,
    sorter: SorterResult<T> | SorterResult<T>[]
  ): Promise<void> => {
    if (pagination.current !== prePagination.current) {
      setCurrent(pagination.current || 1);
    } else {
      setCurrent(1);
    }
    const { field, order } = Array.isArray(sorter) ? sorter[0] : sorter;
    if (field) {
      setOrderByField(String(field));
      setOrder(order || 'asc');
    }
    setPageSize(pagination.pageSize || 10);
  };

  const handleSelectAll = (e: CheckboxChangeEvent): void => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedList(dataSource.map(item => (item as { id: number }).id));
      setSelectAll(true);
    } else {
      setSelectedList([]);
      setSelectAll(false);
    }
  };

  const handleSelect = (id: number): void => {
    setSelectedList(prevSelectedList =>
      prevSelectedList.includes(id)
        ? prevSelectedList.filter(existingId => existingId !== id)
        : [...prevSelectedList, id]
    );
  };

  return {
    onPageChange,
    handleSelectAll,
    handleSelect,
  };
};

import axios from 'axios';
import { BASE_URL, ROLE_API_PATH } from 'constants/api';
import { ApiService } from 'service/apiService';
import { FetchResult } from 'type/apiType';
import { RolePermissionType } from '../type/roleType';

class RoleService {
  constructor(private apiService: ApiService<RolePermissionType>) {}

  async fetch(params?: Record<string, string>): Promise<FetchResult<RolePermissionType>> {
    const response = await this.apiService.fetch(params);
    return response;
  }

  async fetchById(id: number, params?: Record<string, string>): Promise<RolePermissionType> {
    const response = await this.apiService.fetchById(id, params);
    return response;
  }

  async create(formData: FormData): Promise<RolePermissionType> {
    const response = await this.apiService.createByFormData(formData);
    return response;
  }

  async updateRole(id: number, role: RolePermissionType): Promise<RolePermissionType> {
    const response = await this.apiService.update(id, role);
    return response;
  }

  async deleteRole(id: number): Promise<void> {
    await this.apiService.delete(id);
  }

  async updatePermission(id: number, permission: string[]): Promise<void> {
    await axios.patch(`${BASE_URL}${ROLE_API_PATH}${id}/permissions/`, permission);
  }
}

export const roleService = new RoleService(new ApiService<RolePermissionType>(ROLE_API_PATH));

import { message } from 'antd';
import { contentService } from 'content/service';
import { ContentInterface } from 'content/type';
import { NavigateFunction } from 'react-router-dom';
import { accountStore } from 'store/accountStore';

interface ContentHandlersProps {
  navigate: NavigateFunction;
  setContentList?: (contentList: ContentInterface[]) => void;
  setIsModalVisible?: (isVisible: boolean) => void;
  setSelectedRecord?: (record: ContentInterface | null) => void;
}

interface ContentHandlers {
  onClickDeleteButton: (id: number) => Promise<void>;
  handleAddVideoClick: () => void;
  handleOnClickTitle: (id: number) => void;
  handleOnClickSetting: (id: number) => void;
  handleShowModal: (record: ContentInterface) => void;
  handleCancelModal: () => void;
  handleBulkDelete: (ids: number[]) => Promise<void>;
  handleOnDownload: (record: ContentInterface) => void;
}

const contentHandlers = ({
  navigate,
  setIsModalVisible = (): void => {},
  setSelectedRecord = (): void => {},
}: ContentHandlersProps): ContentHandlers => {
  const onClickDeleteButton = async (id: number): Promise<void> => {
    if (!accountStore.hasPermission('content', 'delete')) {
      message.info('You do not have permission');
      return;
    }

    await contentService.deleteContent(id);
    message.success('Deleted successfully');
    return;
  };

  // My Local Files 클릭 시 이동 처리
  const handleAddVideoClick = (): void => {
    navigate('/upload/video');
  };

  const handleOnClickTitle = (id: number): void => {
    navigate(`/video/detail/${id}`);
  };

  const handleOnClickSetting = (id: number): void => {
    navigate(`/video/detail/${id}`);
  };

  const handleShowModal = (record: ContentInterface): void => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleCancelModal = (): void => {
    setIsModalVisible(false);
  };

  const handleBulkDelete = async (ids: number[]): Promise<void> => {
    if (!accountStore.hasPermission('content', 'delete')) {
      message.info('You do not have permission');
      return;
    }

    if (ids.length === 0) {
      message.info('Please select at least one item');
      return;
    }

    await contentService.deleteContents(ids);
    message.success('Deleted successfully');
    window.location.reload();
    return;
  };

  const handleOnDownload = (record: ContentInterface): void => {
    try {
      const link = document.createElement('a');
      const fileUrl = record.contents_file.file || '';
      link.href = fileUrl;
      const fileName = (fileUrl as string).split('/').pop();
      link.setAttribute('download', fileName || 'downloaded-video');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file: ', error);
    }
  };

  return {
    onClickDeleteButton,
    handleAddVideoClick,
    handleOnClickTitle,
    handleOnClickSetting,
    handleShowModal,
    handleCancelModal,
    handleBulkDelete,
    handleOnDownload,
  };
};

export default contentHandlers;

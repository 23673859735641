import { SEASON_SUB_PATH, SERIES_API_PATH } from 'constants/api';
import { SeasonInterface } from 'content/type';
import { ApiService } from 'service/apiService';
import { FetchResult } from 'type/apiType';

class SeriesSeasonItemService {
  constructor(private readonly apiService: ApiService<SeasonInterface>) {}

  public async fetchChildItemsByParentId(seriesId: number): Promise<FetchResult<SeasonInterface>> {
    const response = await this.apiService.fetchChildItemsByParentId(seriesId);
    return response;
  }

  public async createItemsForParent(seriesId: number, itemIds: number[]): Promise<void> {
    return this.apiService.createItemsForParent(seriesId, itemIds);
  }

  public async deleteItemsForParent(seriesId: number, itemIds: number[]): Promise<void> {
    return this.apiService.deleteItemsForParent(seriesId, itemIds);
  }
}

export const seriesSeasonItemService = new SeriesSeasonItemService(
  new ApiService(SERIES_API_PATH, SEASON_SUB_PATH)
);

import * as Component from 'components';
import { seriesService } from 'content/service';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SeriesInterface } from '../../type/seriesType';
import { tableHandlers } from './handlers';
import { getSeriesColumns } from './SeriesColumns';

export default function Series(): React.JSX.Element {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [refreshCounter, setRefreshCounter] = useState(0);
  const { onClickDeleteButton, handleOnClickTitle, handleOnClickSetting, handleAddSeriesClick } =
    tableHandlers({
      navigate,
    });

  const columns = getSeriesColumns({
    handleOnClickTitle,
    handleOnClickSetting,
    onClickDeleteButton,
  });

  const fetchSeriesTableData = useCallback(
    async (params: {
      page: number;
      pageSize: number;
      orderBy?: string;
      search?: string;
    }): Promise<{ data: SeriesInterface[]; count: number }> => {
      const seriesList = await seriesService.fetch({
        page: params.page.toString(),
        page_size: params.pageSize.toString(),
        search: searchTerm,
      });
      return { data: seriesList.results, count: seriesList.count };
    },
    [searchTerm]
  );

  useEffect(() => {
    setRefreshCounter(prev => prev + 1);
  }, [searchTerm]);

  return (
    <Component.BasePageComponent>
      <Component.TableComponent<SeriesInterface>
        key={refreshCounter}
        tableName="Series"
        searchTerm={searchTerm}
        dataFetch={fetchSeriesTableData}
        columns={columns}
        handleSearchTerm={setSearchTerm}
        handleAddClick={handleAddSeriesClick}
      />
    </Component.BasePageComponent>
  );
}

import { DatePicker, Form, Input, Switch } from 'antd';
import { ACTION_TITLE } from 'constants/constant';
import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { getVideoSourceAddHandlers } from './VideoSourceAddHandler';
import VideoSourcePreview from './components/preview/VideoSourcePreview';

const VideoSourceAdd: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();

  const { handleOnClickButton } = getVideoSourceAddHandlers({
    id: id || '',
    form,
    navigate,
  });

  return (
    <div>
      <Component.BasePageComponent>
        <div className="video-source-details-page">
          <h1>Add Video Source</h1>
          <Form form={form} layout="vertical" className="video-source-details-form">
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please enter a name!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="MRSS URL"
              name="mrss_url"
              rules={[{ required: true, message: 'Please enter MRSS URL!' }]}
            >
              <Input />
            </Form.Item>
            <VideoSourcePreview form={form} />
            <Form.Item label="Auto Add Videos" name="auto_add_videos" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item
              label="Auto Activate Videos"
              name="auto_activate_videos"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item label="Category" name="category">
              <Input />
            </Form.Item>
            <Form.Item label="Category Values" name="category_values">
              <Input />
            </Form.Item>
            <Form.Item label="Import Start Date" name="import_from">
              <DatePicker />
            </Form.Item>
            <Form.Item label="Import End Date" name="import_to">
              <DatePicker />
            </Form.Item>
            <Component.ColorButtonComponent text={ACTION_TITLE.ADD} onClick={handleOnClickButton} />
          </Form>
        </div>
      </Component.BasePageComponent>
    </div>
  );
};

export default observer(VideoSourceAdd);

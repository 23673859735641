import { ImportOutlined, SyncOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import * as Component from 'components';
import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { accountStore } from 'store/accountStore';
import { CustomCardComponent } from './components';
import { contentHeaderHandlers } from './components/content-header/ContentHeaderHandlers';
import './ImportUpload.css';

const { Title } = Typography;

const ImportUpload: React.FC = () => {
  const navigate = useNavigate();
  const { handleMyLocalFilesClick, onManageButtonClick, importMrssOnClick, odkSyncOnClick } =
    contentHeaderHandlers({
      navigate,
    });
  const userPlatform = accountStore.getAccount()!.platform;
  return (
    <Component.BasePageComponent>
      <div className="split-container">
        <Title level={2}>Upload</Title>
        <CustomCardComponent
          icon={<UploadOutlined style={{ color: 'black' }} />}
          description="Files"
          buttons={
            <>
              <Button onClick={handleMyLocalFilesClick}>Upload Video From Files</Button>
              <Button
                onClick={() =>
                  onManageButtonClick('video-source/content', { videoSourceType: 'user' })
                }
              >
                Manage Upload Source
              </Button>
            </>
          }
        />
        <Title level={2}>Import</Title>
        <CustomCardComponent
          icon={<ImportOutlined style={{ color: 'black' }} />}
          description="MRSS"
          buttons={
            <>
              <Button onClick={importMrssOnClick}>Add Mrss Source</Button>
              <Button onClick={() => onManageButtonClick('video-source')}>
                Manage Video Source
              </Button>
              <Button
                onClick={() =>
                  onManageButtonClick('video-source/content', { videoSourceType: 'cp' })
                }
              >
                Manage Import vidoes
              </Button>
            </>
          }
        />
        {userPlatform.id === 1 && (
          <>
            <Title level={2}>Sync</Title>

            <CustomCardComponent
              icon={<SyncOutlined style={{ color: 'black' }} />}
              description="ODK"
              buttons={
                <>
                  <Button onClick={odkSyncOnClick}>Setting</Button>
                  <Button
                    onClick={() =>
                      onManageButtonClick('video-source/content', { videoSourceType: 'odk' })
                    }
                  >
                    Manage Sync Videos
                  </Button>
                </>
              }
            />
          </>
        )}
      </div>
    </Component.BasePageComponent>
  );
};

export default observer(ImportUpload);

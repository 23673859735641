import { Checkbox, Table, TableColumnProps } from 'antd';
import { LoadingSpinnerComponent } from 'components';
import React, { useEffect, useState } from 'react';
import './Table.css';
import { useTable } from './tableHandler';
import TableHeader from './TableHeader';

export interface TableColumnInterface<T> extends TableColumnProps {
  title: React.ReactNode;
  dataIndex: string;
  key: string;
  render?: (text: string, record: T, index: number) => React.ReactNode;
  editable?: boolean;
}

export interface TableProps<T> {
  tableName: string;
  dataFetch: (params: {
    page: number;
    pageSize: number;
    orderByField?: string;
    order?: string;
    search?: string;
  }) => Promise<{ data: T[]; count: number }>;
  columns: TableColumnInterface<T>[];
  isDisableIdColumn?: boolean;
  optionalButton?: JSX.Element[];
  searchTerm?: string;
  handleRowExpand?: (record: T) => React.ReactNode;
  handleSearchTerm?: (value: string) => void;
  handleAddClick?: () => void;
  handleBulkDelete?: (ids: number[]) => Promise<void>;
  filterOptions?: { label: string; value: string }[];
  handleFilterChange?: (value: string) => void;
  allowClearFilter?: boolean;
  filterValue?: string;
  onSelectionChange?: (ids: number[]) => void;
}

type TableComponentType = <T extends object>(props: TableProps<T>) => JSX.Element;

const CustomTable: TableComponentType = <T extends object>({
  tableName,
  columns,
  isDisableIdColumn = false,
  dataFetch,
  searchTerm,
  optionalButton,
  handleRowExpand,
  handleSearchTerm,
  handleAddClick,
  handleBulkDelete,
  filterOptions,
  handleFilterChange,
  allowClearFilter,
  filterValue,
  onSelectionChange,
}: TableProps<T>): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dataSource, setDataSource] = useState<T[]>([]);
  const [totalSize, setTotalSize] = useState<number>(0);
  const [current, setCurrent] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedList, setSelectedList] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [order, setOrder] = useState<string>('asc');
  const [orderByField, setOrderByField] = useState<string>('id');

  useEffect(() => {
    onSelectionChange?.(selectedList);
  }, [selectedList, onSelectionChange]);

  const { onPageChange, handleSelectAll, handleSelect } = useTable<T>({
    dataSource,
    setCurrent,
    setPageSize,
    setSelectedList,
    setSelectAll,
    setOrder,
    setOrderByField,
  });

  const idColumn = {
    title: (
      <Checkbox checked={selectAll} onChange={handleSelectAll}>
        ID
      </Checkbox>
    ),
    dataIndex: 'id',
    key: 'id',
    width: '1%',
    render: (_text: string, record: T): React.ReactNode => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="checkbox"
          value={(record as { id: number }).id}
          checked={selectedList.includes((record as { id: number }).id)}
          onChange={() => handleSelect((record as { id: number }).id)}
        />
        <div style={{ paddingLeft: '10px', alignItems: 'center' }}>
          {(record as { id: number }).id}
        </div>
      </div>
    ),
    sorter: true,
  };

  const columnsWithId = isDisableIdColumn ? columns : [idColumn, ...columns];

  useEffect(() => {
    dataFetch({ page: current, pageSize, orderByField, order, search: searchTerm }).then(
      ({ data, count }: { data: T[]; count: number }) => {
        setDataSource(data);
        setTotalSize(count);
        setIsLoading(false);
      }
    );
  }, [current, pageSize, orderByField, order, searchTerm]);

  return (
    <>
      {isLoading ? (
        <LoadingSpinnerComponent />
      ) : (
        <>
          <TableHeader
            tableName={tableName}
            selectedIds={selectedList}
            searchTerm={searchTerm || ''}
            handleSearchTerm={handleSearchTerm}
            handleAddClick={handleAddClick}
            handleBulkDelete={handleBulkDelete}
            optionalButton={optionalButton}
            filterOptions={filterOptions}
            handleFilterChange={handleFilterChange}
            allowClearFilter={allowClearFilter}
            filterValue={filterValue}
          />
          <Table
            dataSource={dataSource}
            columns={columnsWithId}
            pagination={{
              current,
              pageSize: pageSize,
              total: totalSize,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50'],
            }}
            rowKey="id"
            expandable={
              handleRowExpand
                ? {
                    expandedRowRender: handleRowExpand,
                    expandIcon: ({ expanded, onExpand, record }) => (
                      <span
                        onClick={e => onExpand(record, e)}
                        style={{ cursor: 'pointer', paddingRight: '8px' }}
                      >
                        {expanded ? '▼' : '▶'}
                      </span>
                    ),
                  }
                : undefined
            }
            onChange={onPageChange}
          />
        </>
      )}
    </>
  );
};

export default CustomTable;
